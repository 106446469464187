import { tw } from '../styles/tw';

interface AgeRestrictionPlaqueProps {
  className?: string;
}

export const AgeRestrictionPlaque = ({ className }: AgeRestrictionPlaqueProps) => (
  <div
    className={tw(
      'flex h-[3.75rem] w-[3.75rem] items-center justify-center  rounded-full border-2 border-current text-[1.625rem] leading-[2.125rem] text-current',
      className
    )}
  >
    18+
  </div>
);
