import { ButtonVariant, Button } from '../../components/Button';
import { ROUTES } from '../../lib/ROUTES';

/* eslint-disable */
const PrivacyContent = () => (
  <>
    <p>
      Администрация сайта «Spletnik.ru» («Мы»), стремится сохранить конфиденциальность, целостность и безопасность
      персональной информации своих пользователей. Настоящая Политика по защите персональных данных (далее – «Политика»)
      объясняет, как мы защищаем, используем и в определенных случаях распространяем персональную информацию
      Пользователей («Пользователь»/«Вы») сайта, расположенного в сети Интернет по адресу spletnik.ru («Сайт»).
    </p>

    <p>
      Любое использование Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней
      условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен
      воздержаться от какого-либо использования Сайта. Настоящая Политика является неотъемлемой частью Пользовательского
      соглашения Сайта, доступного в сети Интернет по адресу{' '}
      <Button variant={ButtonVariant.InlineText} to={ROUTES.AGREEMENT.buildPath({})} className="text-left">
        https://spletnik.ru/user-agreement.
      </Button>
    </p>

    <h2 className={'text-lg'}>КАК, КОГДА И КАКУЮ ИНФОРМАЦИЮ МЫ СОБИРАЕМ И КАК ЕЕ ИСПОЛЬЗУЕМ</h2>

    <p>В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</p>

    <ul>
      <li>
        – Персональная информация, которую Пользователь предоставляет о себе самостоятельно в процессе использования
        Сайта, включая, но не ограничиваясь: фамилия, имя, отчество, дата рождения, адрес электронной почты, ссылки на
        страницы в социальных сетях.
      </li>
      <li>
        – Данные, которые автоматически передаются нам в процессе использования Сайта с помощью установленного на
        устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, данные Вашего
        интернет-провайдера, в данные о версии программного обеспечения Вашего браузера, об операционной системе
        компьютера, с которого Вы посетили наш сайт, адреса сайтов, после посещения которых Вы по ссылке зашли на наш
        сайт, заданные Вами параметры поиска, приведшие Вас на наш Сайт.
      </li>
    </ul>

    <p>
      Предоставляя нам свои данные, Вы тем самым даете свое согласие на их использование, хранение, обработку и передачу
      (в том числе трансграничную) в соответствии с условиями настоящей Политики.
    </p>

    <p>Мы обрабатываем предоставленными Вами данные в следующих целях:</p>

    <ul>
      <li>– Идентификация Пользователей;</li>
      <li>– Предоставление Пользователю возможности использования функционала Сайта;</li>
      <li>– Связь с Пользователем, в том числе отправка рассылок;</li>
      <li>– Улучшение качества сервиса, удобства использования Сайта, разработка новых возможностей;</li>
      <li>– Проведение статистических и иных исследований на основе обезличенных данных;</li>
      <li>– Маркетинг и реклама наших услуг.</li>
    </ul>

    <p>
      Мы вправе передать Вашу персональную информацию третьим лицам только если вы выразили согласие на такую передачу
      или же если это необходимо для обеспечения работоспособности Сайта, а так же когда необходимость такой передачи
      предусмотрена действующим законодательством РФ.
    </p>

    <p>
      Мы можем предоставить данные третьим лицам при проведении совместных с такими лицами маркетинговых и рекламных
      акций, при этом перечень таких лиц, объем и условия передачи данных отражаются в правилах проведения
      соответствующих маркетинговых и рекламных акций.
    </p>

    <p>
      Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том
      числе в информационных системах персональных данных с использованием средств автоматизации или без использования
      таких средств.
    </p>

    <p>
      Если Вы передумали, Вы можете отозвать данное ранее согласие на использование Ваших данных в любых целях. Для
      этого отправьте нам соответствующий запрос на  электронную почту, указанную ниже в разделе «Вопросы и контактные
      данные».
    </p>

    <h2 className={'text-lg'}>БЕЗОПАСНОСТЬ</h2>

    <p>
      Мы принимаем необходимые и достаточные организационные и технические меры для защиты персональной информации
      Пользователей от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования,
      распространения, а также от иных неправомерных действий с ней третьих лиц в соответствии с требованиями,
      установленными законодательством. 
    </p>

    <p>
      Вы должны понимать, что указанные меры безопасности работают только в нашей системе и на Сайте. Мы не осуществляем
      контроль над тем, как предоставленная вами информация хранится, обрабатывается и распространяется третьими лицами
      или сайтами, принадлежащими третьим лицам.
    </p>

    <h2 className={'text-lg'}>РАЗНОЕ</h2>

    <p>
      Мы вправе вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата
      последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения на Сайте, если иное не
      предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на странице по адресу
      https://spletnik.ru/privacy
    </p>

    <p>
      К настоящей Политике и отношениям, возникающим в связи с применением Политики конфиденциальности, подлежит
      применению право Российской Федерации. Датой выдачи письменного согласия на обработку персональных данных
      Пользователя, является дата ознакомления с настоящей Политикой.
    </p>

    <h2 className={'text-lg'}>ВОПРОСЫ И КОНТАКТНЫЕ ДАННЫЕ</h2>

    <p>
      Если у Вас есть вопросы относительно данной Политики, Вы можете задать их по электронной почте{' '}
      <Button variant={ButtonVariant.InlineText} to="mailto:spletnik.info@spletnik.ru">
        spletnik.info@spletnik.ru
      </Button>
    </p>
  </>
);

/* eslint-enable */

export { PrivacyContent };
