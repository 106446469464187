import { useApolloClient } from '@apollo/client';
import { Stream } from '../lib/graphql.document';

export const useOnPostsNavigation = () => {
  const client = useApolloClient();

  /**
   * If you want to transform this into a high-order function - don't. It's way too easy to get confused and use
   * onPostsNavigation(Stream.Main); instead of onPostsNavigation(Stream.Main)();.
   */
  return (stream: Stream) => {
    client.cache.evict({
      fieldName: 'feedPosts',
      args: {
        pagination: {},
        filter: {
          stream,
        },
      },
    });
    client.cache.gc();

    window.scrollTo(0, 0);
  };
};
