/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AdvertisementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdvertisementsQuery = { __typename?: 'Query', advertisements: Array<{ __typename?: 'Advertisement', id: string, body?: string | null, repeats?: number | null }> };


export const AdvertisementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Advertisements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"advertisements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"body"}},{"kind":"Field","name":{"kind":"Name","value":"repeats"}}]}}]}}]} as unknown as DocumentNode<AdvertisementsQuery, AdvertisementsQueryVariables>;