import { ReactNode, TextareaHTMLAttributes, forwardRef, ForwardedRef, RefAttributes } from 'react';
import { tw } from '../../styles/tw';

type AutosizedTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  value: string;
  renderTextarea?: (
    props: TextareaHTMLAttributes<HTMLTextAreaElement> & RefAttributes<HTMLTextAreaElement>
  ) => ReactNode;
  renderSizeProvider?: (props: SizeProviderProps) => ReactNode;
};

interface SizeProviderProps {
  className: string;
  children: ReactNode;
}

const AutosizedTextarea = forwardRef(
  (
    {
      className,
      value,
      placeholder,
      rows = 1,
      renderTextarea = renderDefaultTextarea,
      renderSizeProvider = renderDefaultSizeProvider,
      ...restProps
    }: AutosizedTextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ): JSX.Element => (
    <div className={tw("grid [grid-template-areas:'textarea']", className)}>
      {renderSizeProvider({
        className: tw('overflow-anywhere invisible whitespace-pre-wrap [grid-area:textarea]'),
        children: (
          <>
            {value || placeholder || '\u00A0'}
            {value.endsWith('\n') ? <br /> : null}
          </>
        ),
      })}
      {renderTextarea({
        className: tw('overflow-anywhere resize-none overflow-hidden whitespace-pre-wrap [grid-area:textarea]'),
        value,
        placeholder,
        rows,
        ref,
        ...restProps,
      })}
    </div>
  )
);

const renderDefaultSizeProvider = (props: SizeProviderProps) => <div {...props} />;

const renderDefaultTextarea = ({ ...props }: TextareaHTMLAttributes<HTMLTextAreaElement>) => <textarea {...props} />;

export { AutosizedTextarea };
export type { AutosizedTextareaProps };
