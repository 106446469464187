import { useOpenState } from '@flame-frontend-utils/commons';
import { useState } from 'react';
import { Sidebar } from '../Sidebar';
import { Menu } from './Menu';
import { layout } from '../../styles/layout';
import { Controls } from './Controls';
import { TagsMenu } from './TagsMenu';
import { HEADER_HEIGHT } from '../../styles/fixedSizes';
import { Button } from '../Button';
import Logo from './icons/logo.svg?svgr';
import { Stream } from '../../lib/graphql.document';
import { ROUTES } from '../../lib/ROUTES';
import { useOnPostsNavigation } from '../../react-hooks/useOnPostsNavigation';
import { NotificationsMenu } from './NotificationsMenu';
import { tw } from '../../styles/tw';
import { MAX_WIDTH } from '../../styles/width';

const Header = (): JSX.Element => {
  const [isMenuOpen, onMenuOpen, onMenuClose] = useOpenState(false);

  /**
   * If we close the modal without going anywhere, we should return focus back to the opening button. However, if we
   * change the page, we should not return focus, because it might mess up autofocus on other pages. The easiest way to
   * distinguish these two cases is to check if the modal was closed from the modal content. The only issue with this
   * approach is that modal content also has a close button, but it's not a big deal, because keyboard users most likely
   * will close the modal with Escape anyway, and this close button will only be used by mouse users, who don't care
   * about focus.
   */
  const [returnMenuFocus, setReturnMenuFocus] = useState(true);

  const onCloseFromModal = () => {
    setReturnMenuFocus(true);
    onMenuClose();
  };

  const onCloseFromModalContent = () => {
    setReturnMenuFocus(false);
    onMenuClose();
  };

  const onPostsNavigation = useOnPostsNavigation();

  const [isNotificationsOpen, onNotificationsOpen, onNotificationsClose] = useOpenState(false);

  const [unviewedOnly, setUnviewedOnly] = useState(false);

  const onNotificationClick = (hasUnviewed: boolean) => {
    setUnviewedOnly(hasUnviewed);
    onNotificationsOpen();
  };

  return (
    <header
      style={{ '--header-height': `${HEADER_HEIGHT / 16}rem`, '--main-max-width': `${MAX_WIDTH / 16}rem` }}
      className={tw(
        layout,
        'sticky inset-x-0 top-0 z-header mt-0 flex h-[var(--header-height)] max-w-[var(--main-max-width)] items-stretch justify-center bg-cream'
      )}
    >
      <nav className="relative flex w-full items-center justify-between border-b border-blood pb-6 pt-5">
        <Button
          onClick={() => onPostsNavigation(Stream.Main)}
          to={ROUTES.ROOT.buildPath({})}
          aria-label={PUBLIC_CONFIG.APP_NAME}
        >
          <div className="h-[30px] w-[114px] md:h-[37px] md:w-[144px]">
            <Logo className="h-full w-full" />
          </div>
        </Button>
        <div className="mx-4 hidden items-center gap-4.5 md:flex">
          <TagsMenu />
        </div>
        <Controls onMenuClick={onMenuOpen} onNotificationsClick={onNotificationClick} />
      </nav>
      <Sidebar isOpen={isMenuOpen} onRequestClose={onCloseFromModal} shouldReturnFocusAfterClose={returnMenuFocus}>
        <Menu onClose={onCloseFromModalContent} />
      </Sidebar>
      <Sidebar
        overlayClassName={tw('bg-overlay/30')}
        isOpen={isNotificationsOpen}
        onRequestClose={onNotificationsClose}
      >
        <NotificationsMenu
          onClose={onNotificationsClose}
          unviewedOnly={unviewedOnly}
          setUnviewedOnly={setUnviewedOnly}
        />
      </Sidebar>
    </header>
  );
};

export { Header };
