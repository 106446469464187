import { Button, ButtonVariant } from '../../components/Button';
import { ROUTES } from '../../lib/ROUTES';

const AgreementContent = () => (
  <>
    <p>
      <strong>Дата последнего изменения – «13» марта 2024 г.</strong>
    </p>

    <p>
      <strong>Термины и определения</strong>
    </p>

    <p>
      <strong>Сайт</strong> - совокупность интегрированных программно-аппаратных и технических средств, а также
      информации, предназначенной для публикации в сети Интернет и отображаемой в определенной текстовой, графической
      или звуковых формах, расположенный в сети Интернет по адресу spletnik.ru
    </p>

    <p>
      <strong>Администрация Сайта</strong> - Общество с ограниченной ответственностью «Креатив Медиа» - юридическое
      лицо, созданное по законодательству Российской Федерации, ОГРН 1067746528410, ИНН 7703590719, адрес места
      нахождения: 125009, Москва, Нижний Кисловский пер., дом 6, стр. 2;
    </p>

    <p>
      <strong>Пользователь</strong> – физическое лицо, использующее функционал Сайта в режиме реального времени и/или
      получившее учетную запись на Сайте в установленном порядке.
    </p>

    <p>
      <strong>Контент</strong> - результаты интеллектуальной деятельности и приравненными к ним средствами
      индивидуализации (в том числе: музыкальные произведения, литературные произведения, программы для ЭВМ, мобильных
      телефонов, аудиовизуальные произведения, фонограммы, изображения, тексты, товарные знаки и знаки обслуживания,
      коммерческие обозначения и фирменные наименования, логотипы), гипертекстовые ссылки, их фрагменты, информация,
      виджеты и иные объекты, размещаемые Сайте.
    </p>

    <p>
      <strong>Пользовательский контент</strong> - контент сайта (включая текстовые материалы, фото, видео), размещаемый
      Пользователем самостоятельно, добровольно и безвозмездно.
    </p>

    <p>
      <strong>1. Общие условия</strong>
    </p>
    <p>
      <strong>1.1.</strong> Настоящее Пользовательское соглашение (далее — Соглашение) устанавливает условия и правила
      использования Сайта, в том числе: условия использования материалов, размещенных на Сайте, условия размещения
      Контента, а также права и обязанности Пользователей и Администрации Сайта.
    </p>
    <p>
      <strong>1.2.</strong> Настоящее Соглашение является обязательным для исполнения документом для всех Пользователей
      Сайта.
    </p>
    <p>
      <strong>1.3.</strong> Пользователь обязан полностью ознакомиться с настоящим Соглашением до начала использования
      Сайта. Какое-либо использование Сайта Пользователем (в том числе регистрация и создание учетной записи) означает,
      что Пользователь принимает и обязуется соблюдать все нижеприведенные условия настоящего Соглашения в соответствии
      со ст.438 Гражданского кодекса Российской Федерации.
    </p>
    <p>
      <strong>1.4.</strong> Настоящее Соглашение полностью или в части может быть изменено Администрацией в любое время
      без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее опубликования на
      Сайте, если иное не предусмотрено условиями новой редакции Соглашения. Администрация рекомендует Пользователям
      регулярно проверять условия настоящего Соглашения на предмет их изменения и/или дополнения. Продолжение
      использования Сайта Пользователем после внесения изменений и/или дополнений в настоящее Соглашение означает
      принятие и согласие Пользователя с такими изменениями и/или дополнениями.
    </p>
    <p>
      <strong>1.5.</strong> Если Пользователь не согласен соблюдать условия настоящего Соглашения, изменений и/или
      дополнений к нему, Пользователь не должен использовать Сайт и его функционал, ему необходимо сообщить об этом
      Администрации и покинуть Сайт. Аккаунт такого Пользователя будет деактивирован Администрацией.
    </p>
    <p>
      <strong>1.6.</strong> Пользователь, принимая условия Соглашения, выражает своё согласие на просмотр рекламных
      материалов при использовании Сайта в количестве, определяемом Администрацией.
    </p>
    <p>
      <strong>1.7.</strong> Обращения, предложения и претензии физических и юридических лиц к Администрации Сайта в
      связи с настоящим Соглашение и всеми вопросами по функционированию Сайта, нарушениями прав и интересов третьих лиц
      на нем или при его использовании, а также запросы уполномоченных законодательством Российской Федерации лиц и
      органов в связи с Сайтом могут быть направлены на почтовый адрес или на адрес электронной почты Администрации,
      указанные в разделе 9 «Контактные данные». Правила подачи обращений о нарушении авторских и иных прав в материалах
      Сайта установлены в разделе Сайта «Для правообладателей».
    </p>
    <p>
      <strong>1.8.</strong> Неотъемлемой частью настоящего Соглашения является Политика конфиденциальности и обработки
      персональных данных, доступная по адресу{' '}
      <Button variant={ButtonVariant.InlineText} to={ROUTES.PRIVACY.buildPath({})} className="text-left">
        https://spletnik.ru/privacy
      </Button>
      .
    </p>

    <p>
      <strong>2.</strong> Правила регистрации на сайте
    </p>
    <p>
      <strong>2.1</strong> При регистрации Пользователь соглашается с условиями настоящего Соглашения и принимает на
      себя указанные в нём права и обязанности, связанные с использованием и функционированием Сайта.
    </p>
    <p>
      <strong>2.2</strong> Пользователем признается лицо, достигшее 18-ти лет, присоединившееся к настоящему Соглашению
      при регистрации на Сайте, чем подтверждается прочтение, понимание и согласие Пользователя на соблюдение всех
      условий настоящего Соглашения.
    </p>
    <p>
      <strong>2.3</strong> Регистрация Пользователя на Сайте является бесплатной и добровольной.
    </p>
    <p>
      <strong>2.4</strong> Для того, чтобы воспользоваться функционалом Сайта и получить права на их использование для
      возможности размещения материалов на общедоступных площадках (функционалах) Сайта (в разделах «Блоги»,
      «Комментарии» и т.п.) Пользователь должен пройти следующую процедуру регистрации:
    </p>
    <p>
      <strong>2.4.1</strong> Пользователю будет предложено ввести почту, заполнить регистрационную форму и установить
      пароль для входа на Сайт или выбрать социальную сеть, через аккаунт которой будет производиться авторизация на
      Сайте (Facebook, Twitter, VK, Одноклассники, Google).
    </p>
    <p>
      <strong>2.5</strong> Пароль Пользователя для доступа на Сайт Администрации не доступен.
    </p>
    <p>
      <strong>2.6</strong> Пользователь не имеет права передавать пароль третьим лицам, несет полную ответственность за
      сохранность своих аутентификационных данных, самостоятельно выбирая способ их хранения.
    </p>
    <p>
      <strong>2.7</strong> Если и пока Пользователем не доказано обратное, любые действия, совершенные с использованием
      его аутентификационных данных, считаются совершенными соответствующим Пользователем. В случае несанкционированного
      доступа к аутентификационным данным, личному кабинету, персональной странице Пользователя, Пользователь обязан
      незамедлительно сообщить об этом Администрации
    </p>
    <p>
      <strong>2.8</strong> После согласия с Соглашением и прохождения процедуры регистрации на Сайте
      Пользователюсоздается уникальная учетная запись, имеющая сетевой адрес вида https://spletnik.ru/user/ник.
    </p>
    <p>
      <strong>2.9</strong> После регистрации учетной записи Пользователь получает право самостоятельно в личных
      некоммерческих целях создавать, размещать, использовать и определять содержание собственных материалов
      (Пользовательского контента) в общедоступных площадках на Сайте (в разделах «Блоги», «Комментарии» и т.п.), в том
      числе добавлять текст, фотографии, аудио- и видео- файлы и пр., а также пользоваться иным функционалом Сайта на
      условиях настоящего Соглашения.
    </p>
    <p>
      <strong>2.10</strong> При регистрации Пользователь не имеет права:
    </p>
    <ol>
      <li>• Выдавать себя за другого Пользователя.</li>
      <li>• Сообщать свои аутентификационные данные для пользования учетной записью пользователя третьим лицам.</li>
      <li>
        • Использовать в качестве имени пользователя слово или словосочетание, которое является грубым, устрашающим или
        непристойным с точки зрения морали и нравственности, умышленно переиначенные логины других Пользователей,
        носящие оскорбительный характер для их обладателей.
      </li>
      <li>
        • Использовать для аватаров картинки с элементами, относящимся к фашистской и националистической символике,
        содержащие порнографические материалы и иные материалы и изображения, противоречащие законодательству РФ.
      </li>
      <li>• Создавать более одной учетной записи для одного Пользователя.</li>
    </ol>
    <p>
      <strong>2.11</strong> Пользователь, желающий удалить Аккаунт, должен направить Администрации запрос на удаление в
      свободной форме, указав в нем реквизиты учетной записи. Запросы направляются в порядке, установленном п. 1.7
      настоящего Соглашения.
    </p>

    <p>
      <strong>3. Условия размещения Пользовательского контента</strong>
    </p>
    <p>
      <strong>3.1</strong> Пользовательский контент размещается на Сайте исключительно по инициативе самих
      Пользователей, без какого-либо контроля с чьей-либо стороны, без предварительного одобрения и/или премодерации
      Администрацией , если иное не предусмотрено условиями Соглашения.
    </p>
    <p>
      <strong>3.2</strong> Администрация производит предварительную модерацию первого материала, размещаемого
      Пользователем, не размещавшим ранее материалы Пользовательского контента на Сайте.
    </p>
    <p>
      <strong>3.3</strong> Пользователь обязуется не размещать на Сайте и не направлять куда-либо посредством Сайта
      любые материалы следующего характера:
    </p>
    <ol>
      <li>
        • нарушающие действующее законодательство Российской Федерации, содержащие угрозы и оскорбления,
        дискредитирующие других лиц, нарушающие права граждан на частную жизнь, носящие характер непристойности;
      </li>
      <li>
        • наносящие вред в той или иной степени чести и достоинству лица, правам и охраняемым законом интересам других
        лиц;
      </li>
      <li>
        • способствующие или содержащие призывы к разжиганию религиозной, расовой или межнациональной розни, содержащие
        попытки разжигания вражды или призывы к насилию, а также признаки экстремизма;
      </li>
      <li>
        • содержащие сведения о способах, методах разработки, изготовления и использования, местах приобретения
        наркотических средств, психотропных веществ и их прекурсоров, а также пропаганду их использования;
      </li>
      <li>• содержащие нецензурную брань;</li>
      <li>
        • иные материалы, которые побуждают других лиц на противоправное поведение, влекущее уголовную,
        гражданско-правовую и иную ответственность или каким-либо образом нарушающее положения законодательства;
      </li>
      <li>• материалы, являющиеся рекламой каких-либо товаров или услуг;</li>
      <li>• материалы, призывающие совершить пожертвование/донаты, оказать материальную помощь в любом виде;</li>
    </ol>

    <p>
      <strong>3.4</strong> Администрация до тех пор, пока не установлено иное, предполагает, что все авторские права
      (имущественного и неимущественного характера) на материалы принадлежат разместившему их на Сайте Пользователю, что
      в этих материалах не нарушаются нематериальные блага и/или иные права третьих лиц, и/или что Пользователем
      получены все необходимые права и/или разрешения на размещение соответствующих материалов на Сайте. В случае, если
      будет установлено, что правообладателем размещенных Пользователями на Сайте материалов является иное лицо и/или
      материалы нарушают нематериальные блага или иные права третьих лиц, эти материалы будут изъяты из свободного
      доступа по первому требованию законного правообладателя и/или лица. Администрация Сайта является информационным
      посредником в понимании ГК РФ в отношении Пользовательского контента.
    </p>
    <p>
      <strong>3.5</strong> Пользователь соглашается с тем, что он несет единоличную полную ответственность в отношении
      Пользовательского контента, в том числе, за содержание материалов и комментариев, соответствие их требованиям
      действующего законодательства Российской Федерации, за нарушения прав третьих лиц на размещаемые материалы и
      возмещает все убытки, понесенные Администрацией в связи с размещением Пользовательского контента.
    </p>
    <p>
      <strong>3.6</strong> Размещая Пользовательский контент, Пользователь тем самым автоматически предоставляет
      Администрации (или подтверждает, что владелец таких материалов предоставил Администрации) бесплатное, постоянное,
      безотзывное, неисключительное право (неисключительную лицензию) на территории всех стран мира на весь срок охраны
      авторского права, предусмотренный законодательством РФ, в отношении таких материалов, на использование материалов
      для любых целей и любыми способами, в том числе предусмотренными статьей 1270 Гражданского Кодекса РФ.
      Администрация Сайта вправе передавать права, указанные в настоящем пункте, третьим лицам. При этом никаких
      дополнительных формальностей, в том числе оформляемых в письменном виде, для подтверждения предоставления
      Пользователем вышеуказанных прав Администрации, не требуется.
    </p>

    <p>
      <strong>4. Условия использования Контента Сайта</strong>
    </p>
    <p>
      <strong>4.1</strong> Сайт содержит материалы, охраняемые авторским правом, товарные знаки и иные охраняемые
      законом материалы, включая, но не ограничиваясь, тексты, фотографии, видеоматериалы, графические изображения,
      музыкальные и звуковые произведения.
    </p>
    <p>
      <strong>4.2</strong> Кроме случаев, установленных настоящим Соглашением, а также действующим законодательством РФ,
      никакой Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен во фрейме,
      опубликован, скачан, передан, продан или иным способом использован целиком или по частям без предварительного
      разрешения правообладателя, кроме случаев, когда правообладатель явным образом выразил свое согласие на свободное
      использование Контента любым лицом.
    </p>
    <p>
      <strong>4.3</strong> Никакие положения настоящего Соглашения не предоставляют Пользователю право на использование
      фирменного наименования, товарных знаков, доменных имен и иных средств индивидуализации Сайта и/или Администрации.
      Право на использование указанных объектов может быть предоставлено Пользователю исключительно по письменному
      соглашению с Администрацией.
    </p>
    <p>
      <strong>4.4</strong> Любое использование Сайта или Контента, кроме разрешенного в настоящем Соглашении или в
      случае явно выраженного согласия правообладателя на такое использование, без предварительного письменного
      разрешения правообладателя, категорически запрещено.
    </p>
    <p>
      <strong>4.5</strong> Сайт может содержать ссылки на сайты третьих лиц в сети Интернет. Указанные третьи лица и их
      сайты не проверяются Администрацией Сайта на соответствие требованиям действующего законодательства
      (достоверности, полноты, законности и т. п.). Администрация Сайта не несет ответственность за любую информацию,
      материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ при переходе по ссылкам с
      Сайта.
    </p>

    <p>
      <strong>5. Права и обязанности Пользователя</strong>
    </p>
    <p>
      <strong>5.1</strong> Пользователь обязуется не допускать нарушения условий настоящего Соглашения каким-либо
      образом.
    </p>
    <p>
      <strong>5.2</strong> При использовании Сайта Пользователь обязан:
    </p>
    <ol>
      <li>
        • соблюдать положения действующего законодательства Российской Федерации, настоящего Соглашения и иных
        специальных документов Администрации, размещенных на Сайте;
      </li>
      <li>
        • информировать Администрацию Сайта о несанкционированном доступе к учетной записи и/или о несанкционированном
        доступе и/или использовании пароля и логина Пользователя;
      </li>
      <li>
        • не предоставлять доступ другим Пользователям к собственной учетной записи или к отдельной содержащейся на ней
        информации в случае, если это может привести к нарушению законодательства РФ и/или настоящего Соглашения,
        специальных документов Администрации Сайта;
      </li>
      <li>
        • не размещать в своих материалах на Сайте информацию и объекты (включая ссылки на них), которые могут нарушать
        права и интересы других лиц;
      </li>
      <li>
        • перед размещением информации и объектов (включая, но не ограничиваясь, изображениями других лиц, чужими
        текстами различного содержания, аудиозаписями и пр.) предварительно оценивать законность их размещения;
      </li>
      <li>
        • хранить в тайне и не предоставлять другим Пользователям и третьим лицам ставшие ему известными в результате
        общения с другими Пользователями и иного использования Сайта персональные данные.
      </li>
    </ol>

    <p>
      <strong>5.3</strong> При использовании Сайта Пользователям запрещается:
    </p>
    <ol>
      <li>
        • воспроизводить, дублировать, копировать, передавать или перепродавать доступ к использованию Сайта для
        каких-либо целей, за исключением случаев, когда такие действия были прямо разрешены Пользователю в соответствии
        с условиями отдельного соглашения с Администрацией;
      </li>
      <li>
        • любым способом, в том числе, но не ограничиваясь, путем обмана, злоупотребления доверием, взлома и пр.
        пытаться получить доступ к логину и паролю других Пользователей;
      </li>
      <li>
        • осуществлять сбор персональных данных других Пользователей с целью их последующей обработки, т.е. совершения
        действий (операций) с персональными данными, включая их сбор, систематизацию, накопление, хранение, уточнение
        (обновление, изменение), использование, распространение (в том числе передачу), обезличивание, блокирование и
        уничтожение;
      </li>
      <li>
        • размещать на Сайте персональные или контактные данные других Пользователей или иных лиц без их
        предварительного согласия;
      </li>
      <li>
        • размещать на Сайте изображения других лиц без их предварительного согласия, за исключением случаев,
        предусмотренных законом, и/или иных объектов без предварительного согласия их правообладателей или других
        законных представителей;
      </li>
      <li>
        • размещать на Сайте копии охраняемых законом результатов интеллектуальной деятельности или их частей при
        отсутствии у Пользователя прав на такое использование;
      </li>
      <li>• осуществлять массовые рассылки сообщений в адрес других Пользователей Сайта без их согласия;</li>
      <li>
        • осуществлять действия, направленные на дестабилизацию функционирования Сайта, осуществлять попытки
        несанкционированного доступа к управлению Сайтом или его закрытым разделам (разделам, доступ к которым разрешен
        только Администрации или модераторам), а также осуществлять любые иные действия, которые могут быть расценены
        как сетевая атака;
      </li>
      <li>
        • загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать вирусы,
        трояны и другие вредоносные программы;
      </li>
      <li>
        • использовать без специального на то разрешения Администрации автоматизированные скрипты (программы) для сбора
        информации на Сайте и/или взаимодействия с Сайтом и его функционалом;
      </li>
      <li>
        • осуществлять использование Сайта иным способом, кроме как через интерфейс, предоставленный Администрацией
        Сайта, за исключением случаев, когда такие действия были прямо разрешены Пользователю в соответствии с отдельным
        соглашением с Администрацией;
      </li>
      <li>
        • размещать, загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом
        использовать любую информацию и материалы, нарушающие действующее законодательство РФ.
      </li>
      <li>
        • размещать любую другую информацию, которая, по мнению Администрации, не соответствует целям создания Сайта,
        ущемляет интересы Пользователей или по другим причинам является нежелательной для размещения на Сайте.
      </li>
    </ol>

    <p>
      <strong>5.4</strong> Пользователь несёт ответственность за нарушение данного Соглашения в соответствии с
      законодательством Российской Федерации.
    </p>
    <p>
      <strong>5.5</strong> В случае нанесения ущерба третьим лицам, другим Пользователям или Сайту Пользователь
      обязуется возместить причиненный ущерб в полном объёме в соответствии с действующим законодательством Российской
      Федерации.
    </p>
    <p>
      <strong>5.6</strong> Пользователь несет ответственность и все расходы (включая возмещение убытков, вреда, штрафов,
      судебных и иных расходов и издержек) в случае предъявления третьими лицами каких-либо претензий, включая, но не
      ограничиваясь претензиями связанными с защитой интеллектуальных прав третьих лиц, и за какие-либо обязательства,
      возникшие у Сайта в связи с требованиями третьих лиц, связанные или возникшие вследствие нарушения Пользователем
      условий настоящего Соглашения. Пользователь обязуется принять все необходимые и возможные меры, направленные на
      исключение Администрации из числа ответчиков.
    </p>

    <p>
      <strong>6. Права и обязанности Администрации</strong>
    </p>
    <p>
      <strong>6.1</strong> Администрация Сайта вправе, но не обязана осуществлять модерацию Пользовательского Контента,
      размещаемого на Сайте. Ничто в настоящем Соглашении не должно расцениваться как обязанность Администрации сайта
      (осуществлять регулярный контроль содержания (модерацию) Пользовательского контента.
    </p>
    <p>
      <strong>6.2</strong> Администрация Сайта вправе удалить любой Контент, в том числе текст, фотографию, комментарий
      Пользователя без уведомления и объяснения причин.
    </p>
    <p>
      <strong>6.3</strong> Администрация Сайта не контролирует соблюдение Пользователями прав на результаты
      интеллектуальной деятельности и средства индивидуализации и не несет ответственности за нарушение их
      Пользователями Сайта.
    </p>
    <p>
      <strong>6.4</strong> Администрация Сайта не дает никаких гарантий, выраженных явно или подразумеваемых,
      относительно размещенного на Сайте Контента.
    </p>
    <p>
      <strong>6.5</strong> Администрация Сайта вправе:
    </p>
    <ol>
      <li>
        • временно приостановить или заблокировать на неопределенный срок доступ Пользователя к Сайту или его отдельным
        функционалам и сервисам в случае, если Пользователем регулярно нарушаются положения настоящего Соглашения;
      </li>
      <li>
        • уничтожать дубликаты учетных записей (аккаунты) Пользователей без какого-либо уведомления Пользователей;
      </li>
      <li>
        • в случае нарушения Пользователем условий настоящего Соглашения или действующего законодательства РФ передавать
        контактные данные, IP-адреса и любую другую информацию о таком Пользователе заинтересованным лицам на основании
        соответствующего запроса;
      </li>
      <li>
        • изменить Сайт или его часть, а также закрыть или приостановить функционирование Сайта без уведомления
        Пользователей;
      </li>
      <li>
        • приостанавливать доступ Пользователя к Сайту для проведения необходимых плановых профилактических или
        ремонтных работ на технических ресурсах;
      </li>
      <li>
        • проводить на Сайте различные конкурсы (далее - Конкурсы), подробная информация о которых дополнительно
        размещается на Сайте. В проводимых Конкурсах могут участвовать только граждане РФ, достигшие 18-ти лет,
        указавшие свои реальные ФИО и другую контактную информацию. Администрация оставляет за собой право не допускать
        до участия в Конкурсах Пользователей, подозреваемых в предоставлении недостоверных данных и сведений, накрутке
        баллов, использовании чужих фотографий и иных материалов, а также нарушающих настоящее Соглашение и/или условия
        соответствующих Конкурсов.
      </li>
    </ol>

    <p>
      <strong>7. Ограничение ответственности</strong>
    </p>
    <p>
      <strong>7.1</strong> Администрация не контролирует и не обязана принимать каких-либо действий, касающихся контроля
      за способом, с помощью которого Пользователи Сайта посещают сайт или с помощью которого пользуются сервисами и
      приложениями Сайта, за тем, какой эффект на Пользователей и посетителей Сайта может оказать размещенный на Сайте
      Контент, каким образом Пользователи или посетители Сайта могут толковать размещенную на сайте информацию; контроля
      за действиями, предпринятыми Пользователями и посетителями Сайта после ознакомления с размещенной на Сайте
      информации. Сайт может содержать или направлять Пользователя и посетителя Сайта по ссылкам на другие сайты,
      содержащие информацию, которая может показаться другим лицам устрашающей или некорректной.
    </p>
    <p>
      <strong>7.2</strong> В связи с тем, что Сайт не контролирует другие сайты и ресурсы, Пользователь Сайта
      соглашается, что Сайт не ответственен за доступ к таким сайтам или ресурсам и за данные, размещенные на таких
      сайтах и ресурсах, которые рекламируют услуги, продукты и иные материалы.
    </p>

    <p>
      <strong>8. Прочие условия</strong>
    </p>
    <p>
      <strong>8.1</strong> Настоящее Соглашение регулируется в соответствии с действующим законодательством Российской
      Федерации. Вопросы, не урегулированные настоящим Соглашением, разрешаются в соответствии с действующим
      законодательством Российской Федерации.
    </p>
    <p>
      <strong>8.2</strong> В случае возникновения любых споров, связанных с исполнением настоящего Соглашения,
      Пользователь, Администрация, третьи лица приложат все усилия для их разрешения путем переговоров. При
      невозможности разрешения споров путем переговоров, они подлежат разрешению в суде по месту нахождения
      Администрации Сайта.
    </p>
    <p>
      <strong>8.3</strong> В случае, если одно или несколько положений настоящего Соглашения будут признаны
      недействительными, остальные положения и условия настоящего Соглашения остаются в силе для всех сторон Соглашения.
    </p>

    <p>
      <strong>9. Контактные данные</strong>
    </p>
    <p>
      Адрес для корреспонденции: 125009, Москва, Нижний Кисловский пер., дом 6, стр. 2<br />
      Адрес электронной почты: spletnik.info@spletnik.ru
    </p>
    <p />
  </>
);

export { AgreementContent };
