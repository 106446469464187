import { ChangeEvent, CSSProperties } from 'react';
import { tw } from '../../styles/tw';

interface SwitchProps {
  value: boolean;
  disabled?: boolean;
  onChange(value: boolean): void;
  className?: string;
  style?: CSSProperties;
}

const Switch = ({ className, style, value, disabled, onChange }: SwitchProps): JSX.Element => {
  const onInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className={tw("inline-grid [grid-template-areas:'switch']", className)} style={style}>
      <input
        className="h-6.5 w-13 cursor-pointer opacity-0 [grid-area:switch] disabled:cursor-default"
        type="checkbox"
        disabled={disabled}
        checked={value}
        onChange={onInnerChange}
      />
      <div className="[input:focus-visible+&]:default-outline pointer-events-none box-content grid h-6.5 w-13 rounded-full bg-half-blood transition-colors duration-100 [grid-area:switch] [grid-template-areas:'thumb'] before:h-6.5 before:w-6.5 before:rounded-full before:border-4 before:border-half-blood before:bg-cream before:opacity-100 before:transition-all before:duration-100 before:will-change-transform before:[grid-area:thumb] after:h-6.5 after:w-6.5 after:rounded-full after:border-4 after:border-cream after:bg-blood after:opacity-0 after:transition-all after:duration-100 after:will-change-transform after:[grid-area:thumb] [input:checked+&]:bg-cream [input:checked+&]:before:translate-x-full [input:checked+&]:before:opacity-0 [input:checked+&]:after:translate-x-full [input:checked+&]:after:opacity-100 [input:disabled+&]:opacity-50" />
    </div>
  );
};

export { Switch };
export type { SwitchProps };
