import { getResizedImageUrl, getResizedImageSrcSet } from '@flame-frontend-utils/tiptap';
import { CSSProperties } from 'react';
import { IMAGE_CONFIG, STYLE, CoverType, MAX_DIMENSIONS, isCropSettingsMap } from './imageConfig';
import { FullCoverFragment } from '../../gql/FullCoverFragment.document';
import { tw } from '../../styles/tw';
import { FlameImageLink, flameImageNonStaticContainer } from '../FlameImageLink';

declare module 'react' {
  interface CSSProperties {
    '--padding-percentage'?: string;
  }
}

interface CoverImageProps {
  cover: FullCoverFragment;
  type: CoverType;
  displaySource?: boolean;
  allowOpenInNewTab?: boolean;
  className?: string;
  style?: CSSProperties;
}

const CoverImage = ({
  cover: { originalUrl, alt, source, cropSettings },
  type,
  displaySource,
  allowOpenInNewTab,
  className,
  style,
}: CoverImageProps) => {
  const maxWidth = MAX_DIMENSIONS[type].width;
  const maxHeight = MAX_DIMENSIONS[type].height;

  const config = IMAGE_CONFIG[type];

  return (
    <div className={tw('w-full', className, STYLE[type])} style={style}>
      <div
        style={{ '--padding-percentage': `${(maxHeight / maxWidth) * 100}%` }}
        className="relative h-0 overflow-hidden pb-[var(--padding-percentage)]"
      >
        <picture
          className={tw('absolute left-0 top-0 h-full w-full', allowOpenInNewTab && flameImageNonStaticContainer)}
        >
          {config.map((image) => (
            <source
              key={image.size}
              width={image.width}
              height={image.height}
              media={`(min-width: ${image.size}px)`}
              srcSet={getResizedImageSrcSet({
                resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
                originalUrl,
                cropSettings: isCropSettingsMap(cropSettings) ? cropSettings.horizontal : undefined,
                width: image.width,
                height: image.height,
              })}
            />
          ))}
          <img
            className="h-full w-full bg-loader/15 object-contain"
            alt={alt || 'Иллюстрация к статье'}
            width={maxWidth}
            height={maxHeight}
            src={getResizedImageUrl({
              resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
              originalUrl,
              cropSettings,
              width: maxWidth,
              height: maxHeight,
            })}
            srcSet={getResizedImageSrcSet({
              resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
              originalUrl,
              cropSettings,
              width: maxWidth,
              height: maxHeight,
            })}
          />
          {allowOpenInNewTab ? <FlameImageLink url={originalUrl} alt={alt || 'Иллюстрация к статье'} /> : null}
        </picture>
      </div>
      {/* TODO: Switch to figcaption when backend crop is ready */}
      {displaySource && source ? <p className={tw('mt-5 text-sm text-text-secondary')}>{source}</p> : null}
    </div>
  );
};

export { CoverImage };
