import { tw } from '../../styles/tw';
import LinkIcon from './link.svg?svgr';

interface FlameImageLinkProps {
  url: string;
  alt?: string;
}

export const flameImageContainer = tw('group relative');
export const flameImageNonStaticContainer = tw('group');

export const FlameImageLink = ({ url, alt }: FlameImageLinkProps) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
    <a
      aria-label={alt ?? ''}
      className="absolute inset-0 md:hidden"
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    />
    <div className="absolute right-0 top-0 hidden overflow-hidden md:block">
      {}
      <a
        aria-label={alt ?? ''}
        className="block -translate-y-full translate-x-full bg-cream/60 p-2 !text-blood opacity-0 outline-0 transition-[opacity,transform] focus-within:bg-cream hover:bg-cream group-focus-within:translate-x-0 group-focus-within:translate-y-0 group-focus-within:opacity-100 group-hover:translate-x-0 group-hover:translate-y-0 group-hover:opacity-100"
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        <LinkIcon />
      </a>
    </div>
  </>
);
