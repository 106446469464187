import { Dispatch } from 'react';

export class Dispatcher<TAction> {
  private pendingActions: TAction[] = [];

  private subscriber: Dispatch<TAction> | undefined;

  public dispatch(action: TAction): void {
    if (this.subscriber) {
      this.subscriber(action);
    } else {
      this.pendingActions.push(action);
    }
  }

  public subscribe(subscriber: Dispatch<TAction>): () => void {
    this.subscriber = subscriber;

    this.pendingActions.forEach((action) => {
      this.subscriber?.(action);
    });

    this.pendingActions = [];

    return () => {
      this.subscriber = undefined;
    };
  }
}
