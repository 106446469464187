import { Button, ButtonVariant } from '../../components/Button';

const JobOpeningsContent = () => (
  <>
    <p>
      Привет! На связи Spletnik. Мы постоянно находимся в поиске интересных тем для материалов и авторов и готовы к
      сотрудничеству в самых разных форматах — как штатно, так и внештатно.
    </p>

    <p>
      Если вы не нашли подходящую вакансию, но хотите в команду Spletnik, напишите нам на{' '}
      <Button variant={ButtonVariant.InlineText} to="mailto:job.redefine.media@gmail.com">
        job.redefine.media@gmail.com
      </Button>
      : расскажите о себе и почему вы хотите работать именно у нас, прикрепите свое резюме и портфолио — мы обязательно
      рассмотрим вашу заявку.
    </p>

    <h1 className="mt-16 text-2xl font-bold leading-6">Вакансии</h1>

    <h2 className="text-xl font-bold leading-6">Редактор ленты новостей</h2>

    <h3 className="mt-8 font-bold">Что нужно будет делать:</h3>
    <p>— писать много новостей на самые разные темы;</p>
    <p>— про недели моды, кота Твикса, иноагентов, &quot;Слово пацана&quot; и Владимира Путина;</p>
    <p>— самостоятельно искать инфоповоды;</p>
    <p>— писать лонгриды;</p>
    <p>— самостоятельно собирать информацию по открытым источникам и брать комментарии у экспертов;</p>
    <p>— иногда ходить на светские мероприятия и оперативно писать репортажи после них;</p>
    <p>— придумывать новые темы;</p>
    <p>— вести еженедельные рубрики;</p>
    <p>— периодически постить в соцсети.</p>

    <h3 className="mt-8 font-bold">Мы ищем человека с опытом в работы в онлайн-СМИ, который:</h3>
    <p>— умеет быстро писать в режиме новостника;</p>
    <p>— умеет искать и проверять информацию;</p>
    <p>— хорошо ориентируется в инфоповестке;</p>
    <p>— любит русский язык;</p>
    <p>— знает английский достаточно хорошо, чтобы читать и писать;</p>
    <p>— спокойно относится к правкам;</p>
    <p>— соблюдает дедлайны;</p>
    <p>— умеет работать в команде;</p>
    <p>— любит поп-культуру и искренне интересуется жизнью звёзд;</p>
    <p>— понимает, как писать этично о сложных темах;</p>
    <p>— может периодически посещать светские мероприятия вечером.</p>

    <h3 className="mt-8 font-bold">Условия:</h3>
    <p>— полностью удалённая работа;</p>
    <p>— график: пн. — пт., с 10.00 до 19.00;</p>
    <p>— оформление по договору ГПХ или в качестве ИП или самозанятого;</p>
    <p>— з/п от 70 тыс. (обсуждается индивидуально).</p>

    <h3 className="mt-8 font-bold">Тестовое задание</h3>
    <p>
      1. Предложите 5 инфоповодов, которые могли бы появиться на Сплетнике сегодня (в день выполнения тестового
      задания).
    </p>
    <p>
      2. Предложите 5 новых героев для Сплетника — знаменитостей, о которых мы пока ещё по каким-либо причинам не
      написали.
    </p>
    <p>
      3. Предложите несколько вариантов заголовков для этого материала:{' '}
      <Button
        variant={ButtonVariant.InlineText}
        to="https://spletnik.ru/triller-pro-seksualno-oderzhimogo-botanika-chernaya-komediya-ili-krinzh-v-chyom-fenomen-filma-soltbern-i-pochemu-vse-o-nyom-govoryat-317679"
      >
        https://spletnik.ru/triller-pro-seksualno-oderzhimogo-botanika-chernaya-komediya-ili-krinzh-v-chyom-fenomen-filma-soltbern-i-pochemu-vse-o-nyom-govoryat-317679
      </Button>
    </p>
    <p>
      4. Перепишите этот текст Сплетника так, чтобы он стал лучше:{' '}
      <Button variant={ButtonVariant.InlineText} to="https://spletnik.ru/317912">
        https://spletnik.ru/317912
      </Button>
    </p>

    <h2 className="mt-16 text-xl font-bold leading-6">Вечерний редактор ленты новостей</h2>

    <h3 className="mt-8 font-bold">Что нужно будет делать:</h3>
    <p>— писать новости на самые разные темы;</p>
    <p>— про недели моды, кота Твикса, иноагентов, &quot;Слово пацана&quot; и Владимира Путина;</p>
    <p>— самостоятельно искать инфоповоды;</p>
    <p>— постить в соцсети.</p>

    <h3 className="mt-8 font-bold">Мы ищем человека с опытом в работы в онлайн-СМИ, который:</h3>
    <p>— умеет быстро писать в режиме новостника;</p>
    <p>— хорошо ориентируется в инфоповестке;</p>
    <p>— умеет искать и проверять информацию;</p>
    <p>— любит русский язык;</p>
    <p>— хорошо знает английский;</p>
    <p>— спокойно относится к правкам;</p>
    <p>— соблюдает дедлайны;</p>
    <p>— умеет работать в команде;</p>
    <p>— любит поп-культуру и искренне интересуется жизнью звёзд;</p>
    <p>— понимает, как писать этично о сложных темах.</p>

    <h3 className="mt-8 font-bold">Условия:</h3>
    <p>— график: пн. — пт., с 20.00 до 01.00;</p>
    <p>— полностью удалённая работа;</p>
    <p>— оформление по договору ГПХ или в качестве ИП или самозанятого;</p>
    <p>— з/п от 70 тыс. (обсуждается индивидуально).</p>

    <h3 className="mt-8 font-bold">Тестовое задание</h3>

    <p>
      1. Предложите 5 инфоповодов, которые могли бы появиться на Сплетнике сегодня (в день выполнения тестового
      задания).
    </p>
    <p>
      2. Предложите 5 новых героев для Сплетника — знаменитостей, о которых мы пока ещё по каким-либо причинам не
      написали.
    </p>
    <p>
      3. Предложите несколько вариантов заголовков для этого материала:{' '}
      <Button
        variant={ButtonVariant.InlineText}
        to="https://spletnik.ru/triller-pro-seksualno-oderzhimogo-botanika-chernaya-komediya-ili-krinzh-v-chyom-fenomen-filma-soltbern-i-pochemu-vse-o-nyom-govoryat-317679"
      >
        https://spletnik.ru/triller-pro-seksualno-oderzhimogo-botanika-chernaya-komediya-ili-krinzh-v-chyom-fenomen-filma-soltbern-i-pochemu-vse-o-nyom-govoryat-317679
      </Button>
    </p>
    <p>
      4. Перепишите этот текст Сплетника так, чтобы он стал лучше:{' '}
      <Button variant={ButtonVariant.InlineText} to="https://spletnik.ru/317912">
        https://spletnik.ru/317912
      </Button>
    </p>

    <p className="mt-16 pb-20 font-bold">
      Резюме, портфолио и тестовое задание присылайте по адресу{' '}
      <Button variant={ButtonVariant.InlineText} to="mailto:job.redefine.media@gmail.com">
        job.redefine.media@gmail.com
      </Button>{' '}
      с названием вакансии в теме письма.
    </p>
  </>
);

export { JobOpeningsContent };
