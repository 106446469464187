interface ApiErrorParams {
  response: unknown;
  code: number;
  message: string;
  isAborted?: boolean;
}

const API_ERROR_NAME = 'ApiError';

class ApiError extends Error {
  public static is(error: unknown): error is ApiError {
    return typeof error === 'object' && error !== null && (error as Error).name === API_ERROR_NAME;
  }

  public override readonly name = API_ERROR_NAME;

  public readonly code: number;

  public readonly response: unknown;

  public readonly isAborted: boolean;

  public constructor({ message, response, code, isAborted = false }: ApiErrorParams) {
    super(message);
    this.response = response;
    this.code = code;
    this.isAborted = isAborted;
  }
}

export { ApiError };
