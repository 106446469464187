import { getResizedImageUrl } from '@flame-frontend-utils/tiptap';
import { isCropSettingsMap, MAX_DIMENSIONS, CoverType } from './imageConfig';

export function getOgImageUrl(
  image?: { originalUrl?: string | null; cropSettings?: unknown } | null
): string | undefined {
  return image?.originalUrl
    ? getResizedImageUrl({
        resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
        originalUrl: image.originalUrl,
        cropSettings: isCropSettingsMap(image.cropSettings) ? image.cropSettings.openGraph : undefined,
        width: MAX_DIMENSIONS[CoverType.OpenGraph].width,
        height: MAX_DIMENSIONS[CoverType.OpenGraph].height,
        dpr: 2,
      })
    : undefined;
}
