import { ReactNode, useContext } from 'react';
import { RouterContext } from '../RouterContextProvider';

interface StatusProps {
  code: number;
  children: ReactNode;
}

const Status = ({ code, children }: StatusProps): JSX.Element => {
  const staticContext = useContext(RouterContext);

  if (staticContext) staticContext.statusCode = code;

  return children as JSX.Element;
};

export { Status };
export type { StatusProps };
