import { useEffect, useState } from 'react';

export function useIsClientSide(): boolean {
  const [isClientSide, setIsClientSide] = useState(false);

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  return isClientSide;
}
