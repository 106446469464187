import { ChangeEvent, MouseEvent, useCallback, useRef, useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useUploadImage } from '../../../react-hooks/useUploadImage';
import { FeedbackFormState } from '../types';

const MAX_FILE_SIZE = 1e7; // 10 MB

export const useImageUpload = (onChange: (file: string) => void, setError: UseFormSetError<FeedbackFormState>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [upload, { loading }] = useUploadImage();
  const [fileName, setFileName] = useState('');
  const abortControllerRef = useRef<AbortController | null>(null);

  const onSelectFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          setError('image', { message: 'Размер файла превышает 10 МБ' });
          return;
        }

        setFileName(file.name);
        abortControllerRef.current = new window.AbortController();

        try {
          const uploadedImage = await upload(
            file,
            {
              defaultPreviews: [1],
            },
            { signal: abortControllerRef.current.signal }
          );
          onChange(uploadedImage.getPreviewUrl('original'));
        } catch (error) {
          onChange('');
        }
      }
    },
    [onChange, setError, upload]
  );

  const onReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChange('');
  };

  const onCancel = (e: MouseEvent<HTMLButtonElement>) => {
    abortControllerRef.current?.abort();
    onReset(e);
  };

  const onUpload = () => inputRef.current?.click();

  return { inputRef, fileName, loading, onSelectFile, onUpload, onCancel, onReset };
};
