import { useQuery } from '@apollo/client';
import { LoginInfoDocument, LoginInfoQuery } from './gql/LoginInfo.document';

type User = LoginInfoQuery['loginInfo']['user'] | undefined;

const useLoginInfo = () => {
  const {
    loading,
    data: currentData,
    previousData,
    refetch,
  } = useQuery(LoginInfoDocument, { fetchPolicy: 'cache-first', ssr: false });

  const data = currentData || previousData;

  return {
    loading,
    isDataReceived: Boolean(data),
    user: data?.loginInfo.user,
    data,
    refetch,
  };
};

export { useLoginInfo };
export type { User };
