import { forwardRef, ForwardedRef } from 'react';
import LoaderIcon from './loader.svg?svgr';
import { tw } from '../../styles/tw';

interface LoaderProps {
  className?: string;
}

const Loader = forwardRef(({ className }: LoaderProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div ref={ref} className={tw('animate-reveal-loader overflow-hidden text-blood', className)}>
    <LoaderIcon className="animate-spin-loader" height={200} width={200} />
  </div>
));

export { Loader };
