export enum ButtonVariant {
  Outlined = 'OUTLINED',
  Contained = 'CONTAINED',
  Text = 'TEXT',
  Underlined = 'UNDERLINED',
  InlineText = 'INLINE_TEXT',
}

export enum ButtonColor {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY',
}
