import { Helmet } from 'react-helmet-async';

interface ViewportProps {
  minimalWidth: number;
}

const Viewport = ({ minimalWidth }: ViewportProps) => (
  <>
    <Helmet>
      {SSR_MODE ? (
        <meta name="viewport" content={`width=${minimalWidth}, initial-scale=1`} />
      ) : (
        <meta
          name="viewport"
          content={`width=${minimalWidth}, initial-scale=${Math.min(window.screen.width / minimalWidth, 1)}`}
        />
      )}
    </Helmet>
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: `document.querySelector('meta[name="viewport"]').setAttribute('content', \`width=${minimalWidth}, initial-scale=\${Math.min(screen.width / ${minimalWidth}, 1)}\`);`,
        },
      ]}
    />
  </>
);

export { Viewport };
