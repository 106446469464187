import { ApolloProvider as ApolloProviderLib, useApolloClient } from '@apollo/client';
import { ApolloProviderProps } from '@apollo/client/react/context';
import { ReactNode, useEffect, useState, createContext, useContext } from 'react';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';

interface CurrentUserDataFetcherProps {
  children: ReactNode;
}

/**
 * SSR result is user-agnostic, so we have to refetch everything if we're actually logged in. Otherwise, there is no
 * need for that.
 */
const ApolloProvider = ({ client, children, ...restProps }: ApolloProviderProps<unknown>): JSX.Element => {
  // eslint-disable-next-line no-param-reassign
  client.disableNetworkFetches = true;

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    client.disableNetworkFetches = false;
  }, [client]);

  return (
    <ApolloProviderLib client={client} {...restProps}>
      <CurrentUserDataFetcher>{children}</CurrentUserDataFetcher>
    </ApolloProviderLib>
  );
};

const CurrentUserDataFetcher = ({ children }: CurrentUserDataFetcherProps) => {
  const { data } = useLoginInfo();
  const client = useApolloClient();

  const [userSpecificDataLoaded, setUserSpecificDataLoaded] = useState(false);

  useEffect(() => {
    const effect = async () => {
      if (data) {
        if (data.loginInfo.user) {
          await client.reFetchObservableQueries();
        }

        setUserSpecificDataLoaded(true);
      }
    };

    void effect();
  }, [client, data]);

  return (
    <UserSpecificDataLoadedContext.Provider value={userSpecificDataLoaded}>
      {children}
    </UserSpecificDataLoadedContext.Provider>
  );
};

const UserSpecificDataLoadedContext = createContext(true);

const useUserSpecificDataLoaded = () => useContext(UserSpecificDataLoadedContext);

export { ApolloProvider, useUserSpecificDataLoaded };
