import { ComponentProps, useContext, useEffect } from 'react';
import { Navigate as RouterNavigate, NavigateProps, useResolvedPath } from 'react-router';
import { RouterContext } from '../RouterContextProvider';

const Navigate = ({
  to,
  replace,
  statusCode,
  ...restProps
}: NavigateProps & { statusCode?: number }): JSX.Element | null => {
  const staticContext = useContext(RouterContext);
  const resolvedTo = useResolvedPath(to);

  useEffect(() => {
    if (isExternalUrl(to)) {
      if (replace) {
        window.location.replace(to);
      } else {
        window.location.assign(to);
      }
    }
  }, [to, replace]);

  if (staticContext) {
    if (isExternalUrl(to)) {
      staticContext.url = to;
    } else {
      staticContext.url = `${resolvedTo.pathname}${resolvedTo.search}${resolvedTo.hash}`;
    }

    staticContext.statusCode = statusCode ?? 302;
  }

  if (staticContext || isExternalUrl(to)) {
    return null;
  }

  return <RouterNavigate to={to} replace={replace} {...restProps} />;
};

function isExternalUrl(to: ComponentProps<typeof RouterNavigate>['to'], isExternal?: boolean): to is string {
  return (
    typeof to === 'string' &&
    (isExternal ||
      to.startsWith('http://') ||
      to.startsWith('https://') ||
      to.startsWith('//') ||
      to.startsWith('mailto:'))
  );
}

export { Navigate, isExternalUrl };
