import { Button, ButtonVariant } from '../../Button';
import ArrowIcon from '../arrow.svg?svgr';
import { useNewComment } from './useNewComment';
import { CommentReceiver } from '../components';
import { UniversalComment } from '../types';
import { tw } from '../../../styles/tw';
import { AutosizedTextarea } from '../../AutosizedTextarea';

interface Props {
  slug: string;
  postId: string;
  receiver?: UniversalComment;
  responseTo?: string;
  renderingId?: string;
  onComplete?: () => void;
  className?: string;
}

const NewCommentForm = ({ slug, postId, renderingId, receiver, responseTo = '', onComplete, className }: Props) => {
  const {
    formRef,
    value,
    loading,
    canSubmit,
    needUser,
    needLogin,
    needNickname,
    onChange,
    onKeyDown,
    onSubmit,
    onLogin,
    onSettingsFormOpen,
  } = useNewComment({
    responseTo,
    renderingId,
    onComplete,
    receiverId: receiver?.id,
    slug,
    postId,
  });

  return (
    <form onSubmit={onSubmit} ref={formRef} className={tw('flex flex-col border-b border-blood pb-3', className)}>
      {receiver ? <CommentReceiver className="mb-3" receiver={receiver} /> : null}
      {!needLogin && !needNickname && !needUser ? (
        <div className="flex items-start">
          <AutosizedTextarea
            className="-ml-px max-h-36 grow resize-none overflow-auto"
            rows={1}
            placeholder="Написать комментарий"
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            renderTextarea={(props) => (
              <textarea
                {...props}
                className={tw(
                  props.className,
                  'outline-none placeholder:text-xs placeholder:text-half-blood md:placeholder:text-base'
                )}
              />
            )}
          />
          <Button className="ml-4 disabled:hidden" type="submit" disabled={!canSubmit || loading}>
            <ArrowIcon width={24} height={24} />
          </Button>
        </div>
      ) : needUser ? (
        <div>Подождите...</div>
      ) : needLogin ? (
        <div>
          <Button variant={ButtonVariant.InlineText} onClick={onLogin}>
            Войдите
          </Button>
          , чтобы написать комментарий
        </div>
      ) : (
        <div>
          <Button
            className="disabled:hidden"
            variant={ButtonVariant.InlineText}
            onClick={() => onSettingsFormOpen('personal')}
          >
            Задайте имя
          </Button>
          , чтобы написать комментарий
        </div>
      )}
    </form>
  );
};

export { NewCommentForm };
