import { Clickable, ClickableProps } from '../Clickable';
import { getVariantStyles } from './styles';
import { getColorStyles } from './colorStyles';
import { ButtonVariant, ButtonColor } from './types';
import { tw } from '../../styles/tw';

type ButtonProps = ClickableProps<{ variant?: ButtonVariant; color?: ButtonColor }>;

const Button = (props: ButtonProps): JSX.Element => {
  const { variant = ButtonVariant.Text, color = ButtonColor.Primary, className, ...rest } = props;

  return <Clickable style={getColorStyles(color)} className={tw(getVariantStyles(variant), className)} {...rest} />;
};

export { Button };
export type { ButtonProps };
