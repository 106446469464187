import { createContext, ReactNode, useContext } from 'react';

interface ServerDataProviderProps {
  data: typeof window.SERVER_DATA;
  children: ReactNode;
}

const ServerDataProvider = ({ data, children }: ServerDataProviderProps): JSX.Element => (
  <ServerDataContext.Provider value={data}>{children}</ServerDataContext.Provider>
);

const ServerDataContext = createContext<typeof window.SERVER_DATA | undefined>(undefined);

const useServerData = () => {
  const data = useContext(ServerDataContext);

  if (!data) {
    throw new Error('useServerData must be used within a ServerDataProvider');
  }

  return data;
};

export { ServerDataProvider, useServerData };
