import { createContext, ReactNode, useContext, useEffect, useState, useCallback } from 'react';
import { useOpenState } from '@flame-frontend-utils/commons';
import { dismissFloatingAds } from '../lib/dismissFloatingAds';
import { Sidebar } from './Sidebar';
import { Settings, SettingsMenuItemKey } from './Settings';
import { tw } from '../styles/tw';

interface LoginFormProviderProps {
  children?: ReactNode;
}

const SettingsFormProvider = ({ children }: LoginFormProviderProps) => {
  const [isOpen, onInnerOpen, onClose] = useOpenState();
  const [initialActiveKey, setInitialActiveKey] = useState<SettingsMenuItemKey | undefined>();

  const onOpen = useCallback(
    (key?: SettingsMenuItemKey) => {
      setInitialActiveKey(key);
      onInnerOpen();
    },
    [onInnerOpen]
  );

  useEffect(() => {
    if (isOpen) {
      dismissFloatingAds();
    }
  }, [isOpen]);

  return (
    <OnSettingsFormOpenContext.Provider value={onOpen}>
      <>
        {children}
        <Sidebar overlayClassName={tw('bg-overlay/30')} isOpen={isOpen} onRequestClose={onClose}>
          <section className="flex w-full grow flex-col items-stretch bg-cream px-8 py-5 md:w-[33.875rem]">
            <Settings onClose={onClose} initialActiveKey={initialActiveKey} />
          </section>
        </Sidebar>
      </>
    </OnSettingsFormOpenContext.Provider>
  );
};

const OnSettingsFormOpenContext = createContext<(key?: SettingsMenuItemKey) => void>(() => {});

const useOnSettingsFormOpen = () => useContext(OnSettingsFormOpenContext);

export { SettingsFormProvider, useOnSettingsFormOpen };
