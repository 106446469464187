import { InMemoryCacheConfig } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { simpleForwardPagination } from './simpleForwardPagination';

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    LoginInfo: {
      keyFields: [],
    },
    Settings: {
      keyFields: [],
    },
    Query: {
      fields: {
        mediaPosts: simpleForwardPagination(['filter']),
        feedPosts: simpleForwardPagination(['filter']),
        userPosts: simpleForwardPagination(['filter']),
        publishedPosts: simpleForwardPagination(['filter']),
        dailyPosts: simpleForwardPagination(['filter'], 'date'),
        comments: simpleForwardPagination(['filter', 'order']),
        notifications: relayStylePagination(['filter']),
      },
    },
    MediaPost: {
      fields: {
        revenue: { merge: true },
      },
    },
  },
};
