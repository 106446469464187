import ReplyIcon from './reply.svg?svgr';
import ReplySmallIcon from './reply-small.svg?svgr';
import { Avatar } from '../../../Avatar';
import { getDisplayedName } from '../../../../lib/getDisplayedName';
import { Button } from '../../../Button';
import { UniversalComment } from '../../types';
import { tw } from '../../../../styles/tw';

interface CommentReceiverProps {
  receiver: Pick<UniversalComment, 'author' | 'id'>;
  onClick?: () => void;
  inline?: boolean;
  className?: string;
}

const CommentReceiver = ({ receiver, inline, onClick, className }: CommentReceiverProps) => {
  const { nickname, alias, avatar } = receiver.author;
  const displayedName = getDisplayedName(nickname, alias);

  const onInnerClick = () => {
    document.getElementById(receiver.id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const content = (
    <>
      <ReplySmallIcon className="mr-0.5 shrink-0 text-blood md:hidden" />
      <ReplyIcon className="mr-3 hidden shrink-0 text-blood md:block" />
      <p className="mr-2 truncate">{displayedName}</p>
      {inline ? null : (
        <Avatar
          className="ml-0 h-6 w-6 shrink-0 text-[0.75rem] md:h-8 md:w-8 md:text-[1.125rem]"
          name={displayedName}
          image={avatar}
          alt={displayedName}
          size={23}
        />
      )}
    </>
  );

  return inline ? (
    <Button className={tw('float-left flex max-w-full items-center', className)} onClick={onClick || onInnerClick}>
      {content}
    </Button>
  ) : (
    <div className={tw('flex max-w-full items-center', className)}>{content}</div>
  );
};

export { CommentReceiver };
