import { ToastContainer } from 'react-toastify';
// eslint-disable-next-line import/no-internal-modules
import 'react-toastify/dist/ReactToastify.css';
import { tw } from '../../styles/tw';

const Toast = () => (
  <ToastContainer
    className={tw('text-sm', toastStyles)}
    icon={false}
    closeButton={false}
    theme="colored"
    position="bottom-left"
  />
);

/** @see https://github.com/tailwindlabs/tailwindcss/issues/8881#issuecomment-1208600668 */
const toastStyles = tw(
  String.raw`[&_.Toastify\_\_toast-theme--colored.Toastify\_\_toast--error]:bg-error [&_.Toastify\_\_toast]:rounded-lg [&_.Toastify\_\_toast]:bg-blood [&_.Toastify\_\_toast]:font-medium [&_.Toastify\_\_toast]:text-grey`
);

export { Toast };
