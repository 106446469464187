const ModerationRulesContent = () => (
  <>
    <p>
      1. Удаляем контент рекламного характера, ссылки на сторонние проекты, а также ссылки, не имеющие отношения к
      тематике Сайта.
    </p>
    <p>
      2. Удаляем грубые, оскорбительные комментарии в адрес интернет-пользователей, Администрации/Редакции Сайта,
      модераторов и/или упомянутых в материалах Сайта лиц, а также комментарии, содержащие ненормативную лексику.
    </p>
    <p>
      3. Удаляем является контент, нарушающий законодательство, права граждан или правила Пользовательского соглашения
      Сайта.
    </p>
    <p>
      4. Удаляем контент, не несущий смысловой нагрузки, а также не имеющий никакого отношения к тематике Сайта или
      противоречащий моральным и этическим нормам.
    </p>
    <p>5. Удаляем контент, разжигающий религиозную, межнациональную и/или любую иную вражду.</p>
    <p>
      6. По требованию правообладателя мы удаляем контент, нарушающий авторские и иные права третьих лиц. Правила
      обращения для правообладателей
    </p>
    <p>
      7. В разделе «Блоги» нет списка запрещенных к обсуждениям персон. Однако, Администрация/Редакция Сайта оставляет
      за собой право удалять материалы о детях по просьбам их родителей или законных представителей. Кроме того,
      отдельные материалы о личной и семейной жизни граждан (в т.ч. о членах их семей) также могут быть удалены по
      просьбе самого гражданина в интересах охраны его частной жизни.
    </p>
    <p>
      8. Администрация/Редакция Сайта оставляет за собой право закрыть опцию «комментарии к посту», если более двух
      третей комментариев к нему носят негативный характер. Степень негативности определяется Администрацией/Редакцией
      Сайта или модератором.
    </p>
    <p>
      9. Администрация/Редакция Сайта оставляет за собой право удалять контент, посвященный политике в чистом виде, так
      как эта тема не имеет прямого отношения к содержанию Сайта.
    </p>
    <p>
      10. Удаляем материалы, которые могут быть расценены как пропаганда алкогольной продукции, в т.ч. содержащие
      изображения бокалов с алкоголем и логотипов алкогольных брендов, а также изображения, содержащие демонстрацию
      табачных изделий и процесса потребления табака.
    </p>
    <p>11. Удаляем контент, который может быть расценен как пропаганда нетрадиционных сексуальных отношений.</p>
    <p>
      12. Удаляем материалы и комментарии, которые могут быть расценены как экстремистские или направленные на нарушение
      территориальной целостности РФ.
    </p>
  </>
);

export { ModerationRulesContent };
