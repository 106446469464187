import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '../../../Button';
import { Loader } from '../../../Loader';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import Bin from './bin.svg?svgr';
import { UserPermission } from '../../../../lib/graphql.document';
import { DeleteCommentDocument } from './gql/DeleteComment.document';
import { UniversalComment } from '../../types';
import { hasPermission } from '../../../../lib/apollo';
import { ignoreReject } from '../../../../lib/ignoreReject';

interface CommentDeleteButtonProps {
  comment: UniversalComment;
}

const CommentDeleteButton = ({
  comment: {
    id: commentId,
    author: { id: authorId },
  },
}: CommentDeleteButtonProps) => {
  const { user } = useLoginInfo();
  const [deleteComment, { loading }] = useMutation(DeleteCommentDocument);
  const onDelete = useCallback(
    () => ignoreReject(deleteComment({ variables: { id: commentId } })),
    [commentId, deleteComment]
  );

  if (!user) {
    return null;
  }

  const { id, permissions } = user;

  const isVisible = id === authorId || hasPermission(permissions, UserPermission.Moderate);

  return isVisible ? (
    <>
      <Button className="ml-auto h-6 w-6 md:h-6.5 md:w-6.5" onClick={onDelete} disabled={loading}>
        <Bin />
      </Button>
      {loading && <Loader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
    </>
  ) : null;
};

export { CommentDeleteButton };
