import { MouseEvent } from 'react';
import { Button } from '../../Button';

interface UploadedFileProps {
  title: string;
  buttonText: string;
  isButtonDisabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const FileUploadState = ({ title, buttonText, isButtonDisabled, onClick }: UploadedFileProps) => (
  <div className="flex w-full flex-row items-start justify-between gap-3">
    <p>{title}</p>
    <Button onClick={onClick} disabled={isButtonDisabled}>
      {buttonText}
    </Button>
  </div>
);

export { FileUploadState };
