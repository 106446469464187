import { useRef, useCallback, createContext, useContext, ReactNode, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { AdvertisementId } from './constants';
import { ViewAdDocument } from './ViewAd.document';

interface AdvertisementViewReporterProps {
  postId: string;
  children?: ReactNode;
}

const SEND_VIEWS_WAIT = 2500; // ms

const AdvertisementViewReporter = ({ children, postId }: AdvertisementViewReporterProps) => {
  const viewsRef = useRef<AdvertisementId[]>([]);

  const [viewAd] = useMutation(ViewAdDocument, { context: { disableRetry: true } });

  const onView = useCallback((id: AdvertisementId) => {
    viewsRef.current.push(id);
  }, []);

  useEffect(() => {
    let isCleared = false;
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    const onTimeout = async () => {
      if (!isCleared) {
        if (viewsRef.current.length) {
          const viewsToSend = viewsRef.current;
          viewsRef.current = [];

          try {
            await viewAd({ variables: { postId, advertisements: viewsToSend } });
          } catch (e) {
            viewsRef.current.unshift(...viewsToSend);
          }
        }

        timeoutId = setTimeout(onTimeout, SEND_VIEWS_WAIT);
      }
    };

    void onTimeout();

    return () => {
      isCleared = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [postId, viewAd]);

  return <OnViewContext.Provider value={onView}>{children}</OnViewContext.Provider>;
};

const OnViewContext = createContext<((id: AdvertisementId) => void) | null>(null);

const useOnView = () => useContext(OnViewContext);

export { AdvertisementViewReporter, useOnView };
