import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import { ColorScheme, ColorSchemeVariant, useSetColorScheme } from '@flame-frontend-utils/commons';
import { useEffect } from 'react';
import { ThemeDocument } from './gql/Theme.document';
import { Theme } from '../../lib/graphql.document';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';

const StaticHelmet = (): JSX.Element => {
  const { data: currentData, previousData } = useQuery(ThemeDocument, { ssr: false });
  const data = currentData || previousData;

  const theme = data?.settings.theme;

  const setColorScheme = useSetColorScheme();

  const { data: loginData } = useLoginInfo();

  const authAttribute = loginData ? { 'data-authenticated': String(Boolean(loginData.loginInfo.user)) } : undefined;

  useEffect(() => {
    if (theme) {
      setColorScheme(
        ColorScheme.Light,
        theme === Theme.Primary ? ColorSchemeVariant.Primary : ColorSchemeVariant.Secondary
      );
    }
  }, [setColorScheme, theme]);

  return (
    <Helmet>
      <html lang="ru" {...authAttribute} />
      <meta charSet="utf-8" />
      <meta name="referrer" content="strict-origin-when-cross-origin" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f74c25" />
      <meta name="msapplication-TileColor" content="#f74c25" />
      <title>{PUBLIC_CONFIG.APP_NAME}</title>
    </Helmet>
  );
};

export { StaticHelmet };
