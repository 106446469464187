import { Modal, ModalProps } from '@flame-frontend-utils/commons';
import {
  CLOSE_ANIMATION_DURATION_MS,
  contentAfterOpen,
  contentBase,
  contentBeforeClose,
  modalLayer,
  overlayAfterOpen,
  overlayBase,
  overlayBeforeClose,
  subModalLayer,
} from './styles';
import { tw } from '../../styles/tw';

type SidebarProps = Omit<ModalProps, 'className' | 'overlayClassName'> & {
  className?: string;
  overlayClassName?: string;
  isSubModal?: boolean;
};

const Sidebar = ({ className, overlayClassName, isOpen, isSubModal, ...props }: SidebarProps): JSX.Element => (
  <Modal
    {...props}
    isOpen={isOpen}
    closeTimeoutMS={CLOSE_ANIMATION_DURATION_MS}
    overlayClassName={{
      base: tw(overlayBase, isSubModal ? subModalLayer : modalLayer, overlayClassName),
      beforeClose: overlayBeforeClose,
      afterOpen: overlayAfterOpen,
    }}
    className={{
      base: tw(contentBase, className),
      beforeClose: contentBeforeClose,
      afterOpen: contentAfterOpen,
    }}
  />
);

export { Sidebar };
