import { RetryLink } from '@apollo/client/link/retry';

const INITIAL_DELAY = 300; // ms
const MAX_DELAY = 5000; // ms
const MAX_RETRIES = 5;

export const retryLink = new RetryLink({
  delay: {
    initial: INITIAL_DELAY,
    max: MAX_DELAY,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    const { disableRetry, maxRetries } = operation.getContext();

    return Boolean(error) && !disableRetry && count < (maxRetries || MAX_RETRIES);
  },
});
