import { useEffect } from 'react';
import { useIsHydratedRef } from '../HydrateStatusRefProvider';

const ScrollToTopOnMount = (): null => {
  const isHydratedRef = useIsHydratedRef();

  useEffect(() => {
    if (isHydratedRef.current) {
      window.scrollTo(0, 0);
    }
  }, [isHydratedRef]);

  return null;
};

export { ScrollToTopOnMount };
