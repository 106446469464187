import { UserPermission } from '../graphql.document';

function hasPermission(userPermissions: UserPermission[], modernPermission: UserPermission): boolean {
  const legacyPermission = getLegacyPermission(modernPermission);

  return userPermissions.includes(modernPermission) || userPermissions.includes(legacyPermission);
}

function getLegacyPermission(permission: UserPermission): UserPermission {
  switch (permission) {
    case UserPermission.Blogging:
      return UserPermission.BloggingLegacy;
    case UserPermission.CreateAdvertisement:
      return UserPermission.CreateAdvertisementLegacy;
    case UserPermission.ManageAdvertisement:
      return UserPermission.ManageAdvertisementLegacy;
    case UserPermission.Moderate:
      return UserPermission.ModerateLegacy;
    case UserPermission.PublishArticle:
      return UserPermission.PublishArticleLegacy;
    case UserPermission.RequestEmail:
      return UserPermission.RequestEmailLegacy;
    case UserPermission.SearchByEmail:
      return UserPermission.SearchByEmailLegacy;
    case UserPermission.UpdateUser:
      return UserPermission.UpdateUserLegacy;
    case UserPermission.ViewRevenue:
      return UserPermission.ViewRevenueLegacy;
    case UserPermission.WriteArticle:
      return UserPermission.WriteArticleLegacy;
    default:
      throw new Error(`Unknown permission: ${permission}`);
  }
}

export { hasPermission };
