import maxCoverDimensions from '@flame-frontend-utils/tiptap/configs/river/maxCoverDimensions.json';
import { tw } from '../../styles/tw';
import { Width } from '../../styles/width';

type ImageSizeParams = {
  size: Width;
  width: number;
  height: number;
};

enum CoverType {
  Promo = 'PROMO',
  Regular = 'REGULAR',
  Recommendation = 'RECOMMENDATION',
  OpenGraph = 'OPEN_GRAPH',
}

const MAX_DIMENSIONS: Record<CoverType, { width: number; height: number }> = {
  [CoverType.Promo]: {
    width: 1011,
    height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 1011,
  },
  [CoverType.Regular]: {
    width: 675,
    height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 675,
  },
  [CoverType.Recommendation]: {
    width: 119,
    height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 119,
  },
  [CoverType.OpenGraph]: {
    width: maxCoverDimensions.openGraph.width,
    height: maxCoverDimensions.openGraph.height,
  },
};

const IMAGE_CONFIG: Record<CoverType, ImageSizeParams[]> = {
  [CoverType.Promo]: [
    {
      size: Width.Xl,
      width: 744,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 744,
    },
    {
      size: Width.L,
      width: 893,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 893,
    },
    {
      size: Width.M,
      width: 1011,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 1011,
    },
    {
      size: Width.S,
      width: 675,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 675,
    },
  ],
  [CoverType.Regular]: [
    {
      size: Width.Xl,
      width: 348,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 348,
    },
    {
      size: Width.L,
      width: 434,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 434,
    },
    {
      size: Width.M,
      width: 482,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 482,
    },
    {
      size: Width.S,
      width: 675,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 675,
    },
  ],
  [CoverType.Recommendation]: [
    {
      size: Width.S,
      width: 119,
      height: (maxCoverDimensions.horizontal.height / maxCoverDimensions.horizontal.width) * 119,
    },
  ],
  [CoverType.OpenGraph]: [
    { size: Width.S, width: maxCoverDimensions.openGraph.width, height: maxCoverDimensions.openGraph.height },
  ],
};

const STYLE: Record<CoverType, string> = {
  [CoverType.Promo]: tw('max-w-[42.1875rem] md:max-w-[63.1875rem] lg:max-w-[55.8125rem] xl:max-w-[46.5rem]'),
  [CoverType.Regular]: tw('max-w-[42.1875rem] md:max-w-[30.125rem] lg:max-w-[27.125rem] xl:max-w-[21.75rem]'),
  [CoverType.Recommendation]: tw('max-w-[7.4375rem]'),
  [CoverType.OpenGraph]: tw('max-w-[37.5rem]'),
};

function isCropSettingsMap(map: unknown): map is Record<keyof typeof maxCoverDimensions, unknown> {
  return Boolean(map && typeof map === 'object');
}

export { IMAGE_CONFIG, STYLE, MAX_DIMENSIONS, CoverType, isCropSettingsMap };
export type { ImageSizeParams };
