import { Page } from '../../components/Page';

interface RenderErrorPageProps {
  error: Error;
}

const RenderErrorPage = ({ error }: RenderErrorPageProps): JSX.Element => (
  <Page
    className="mx-5 flex flex-col items-center justify-center whitespace-pre-line"
    documentTitle="Внутренняя ошибка"
    statusCode={500}
    hideAdvertisement
  >
    Произошла внутренняя ошибка. Попробуйте обновить страницу.{'\n'}
    {error.message}
  </Page>
);

export { RenderErrorPage };
export type { RenderErrorPageProps };
