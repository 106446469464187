import { route, string, types, union } from 'react-router-typesafe-routes/dom';
import { POST_MESSAGE_FRAGMENT } from '@flame-frontend-utils/commons-router6';
import { SearchSection, UserPageContent, ShowcaseComponent } from './routeParams';

const ROOT = route('', {}, { LEGACY_PAGE: route('page/:legacyPage?') });

const FRAGMENTS = {
  WITH_ID: route(':id'),
  WITH_SEARCH: route('', { searchParams: { query: string().default('') } }),
};

const ROUTES = {
  ROOT,
  POST: route(
    ':section?/:subsection?/:slug',
    { searchParams: { parentCommentId: string(), targetCommentId: string() } },
    { LEGACY_COMMENTS: route('comments/:legacyPage?') }
  ),
  BLOGS: route('blogs'),
  USER_FILTER: route('user/:id', {
    searchParams: { section: union(...Object.values(UserPageContent)) },
  }),
  TAG_FILTER: route('tag/:id', {}, { LEGACY_PAGE: route('page/:legacyPage?') }),
  BLOG_EDIT: route('edit/:id', { searchParams: { backTo: string() } }),
  LEGACY_TAG: route('tag'),
  SEARCH: route(
    'search',
    types(FRAGMENTS.WITH_SEARCH)({
      searchParams: { section: union(...Object.values(SearchSection)).default(SearchSection.Articles) },
    })
  ),
  ABOUT: route('about'),
  PRIVACY: route('privacy'),
  AGREEMENT: route('user-agreement'),
  MODERATION_RULES: route('moderation-rules'),
  JOB_OPENINGS: route('job-openings'),
  ADVERTISING: route('advertising'),
  COMPONENT_SHOWCASE: route(
    'component-showcase',
    {},
    {
      COMPONENT: route(':component', {
        params: { component: union(...Object.values(ShowcaseComponent)).default(ShowcaseComponent.Clickable) },
      }),
    }
  ),
  POST_MESSAGE: route('post-message', types(POST_MESSAGE_FRAGMENT)),
  RSS: route('rss'),
  LEGACY_RSS: route(':a/:b?/:c?/:d?/rss'),
  LEGACY_RSS_FEED: route('feed.rss'),
  LEGACY_RSS_YANDEX: route('rss-yandex/main'),
  LEGACY_RSS_MIRTESEN: route('rss-mirtesen/main'),
  LEGACY_RSS_MAILRU: route('rss-mailru/main'),
  LEGACY_RSS_ANEWS: route('rss-anews/main'),
  NOT_FOUND: route('*'),
};

const CMS_ROUTES = {
  CALENDAR: route('calendar'),
  POST: route('post/:id'),
};

export { ROUTES, FRAGMENTS, CMS_ROUTES };
