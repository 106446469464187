import { useCallback, useState } from 'react';

export function useOpenState(initialIsOpen?: boolean): [boolean, () => void, () => void] {
  const [isOpen, setIsOpen] = useState(Boolean(initialIsOpen));

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, onOpen, onClose];
}

export function useToggledOpenState(initialIsOpen?: boolean): [boolean, () => void] {
  const [isOpen, setIsOpen] = useState(Boolean(initialIsOpen));

  const onToggle = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return [isOpen, onToggle];
}
