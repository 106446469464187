import { Helmet as HelmetLib } from 'react-helmet-async';
import { createContext, ReactNode, useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react';

interface ColorSchemeProviderProps {
  children: ReactNode;
  /**
   * Local helmet apparently uses separate library instance, which breaks helmet context usage, at least in case when
   * commons package is not built.
   */
  Helmet: typeof HelmetLib;
  lightThemeColor: string;
  darkThemeColor: string;
  defaultColorScheme?: ColorScheme;
  defaultColorSchemeVariant?: ColorSchemeVariant;
}

enum ColorScheme {
  Light = 'light',
  Dark = 'dark',
  Auto = 'auto',
}

enum ColorSchemeVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

const COLOR_SCHEME_KEY = 'prefers-color-scheme';
const COLOR_SCHEME_DATA_ATTRIBUTE = 'data-prefers-color-scheme';
const COLOR_SCHEME_DATA_PROPERTY = 'prefersColorScheme';

const COLOR_SCHEME_VARIANT_KEY = 'prefers-color-scheme-variant';
const COLOR_SCHEME_VARIANT_DATA_ATTRIBUTE = 'data-prefers-color-scheme-variant';
const COLOR_SCHEME_VARIANT_DATA_PROPERTY = 'prefersColorSchemeVariant';

const ColorSchemeProvider = ({
  children,
  Helmet,
  lightThemeColor,
  darkThemeColor,
  defaultColorScheme = ColorScheme.Auto,
  defaultColorSchemeVariant = ColorSchemeVariant.Primary,
}: ColorSchemeProviderProps): JSX.Element => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>();
  const [colorSchemeVariant, setColorSchemeVariant] = useState<ColorSchemeVariant>();
  const [renderedColorScheme, setRenderedColorScheme] = useState<ColorScheme.Dark | ColorScheme.Light>();

  const syncRenderedColorScheme = useCallback(
    (nextColorScheme: ColorScheme.Dark | ColorScheme.Light, nextColorSchemeVariant?: ColorSchemeVariant) => {
      setRenderedColorScheme(nextColorScheme);

      document.documentElement.setAttribute(COLOR_SCHEME_DATA_ATTRIBUTE, nextColorScheme);

      if (nextColorSchemeVariant) {
        document.documentElement.setAttribute(COLOR_SCHEME_VARIANT_DATA_ATTRIBUTE, nextColorSchemeVariant);
      }

      const twitterMeta = document.querySelector('meta[name="twitter:widgets:theme"]') as HTMLMetaElement | undefined;
      const themeColorMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement | undefined;

      if (twitterMeta) {
        twitterMeta.content = nextColorScheme;
      }
      if (themeColorMeta) {
        themeColorMeta.content = nextColorScheme === ColorScheme.Dark ? darkThemeColor : lightThemeColor;
      }
    },
    [darkThemeColor, lightThemeColor]
  );

  const updateColorScheme = useCallback(
    (nextColorScheme: ColorScheme, nextColorSchemeVariant?: ColorSchemeVariant) => {
      if (isLocalStorageAccessible()) {
        if (
          nextColorScheme !== normalizeColorScheme(localStorage.getItem(COLOR_SCHEME_KEY), defaultColorScheme) ||
          (nextColorSchemeVariant &&
            nextColorSchemeVariant !==
              normalizeColorSchemeVariant(localStorage.getItem(COLOR_SCHEME_VARIANT_KEY), defaultColorSchemeVariant))
        ) {
          localStorage.setItem(COLOR_SCHEME_KEY, nextColorScheme);
          setColorScheme(nextColorScheme);

          if (nextColorSchemeVariant) {
            localStorage.setItem(COLOR_SCHEME_VARIANT_KEY, nextColorSchemeVariant);
            setColorSchemeVariant(nextColorSchemeVariant);
          }

          if (nextColorScheme !== ColorScheme.Auto) {
            syncRenderedColorScheme(nextColorScheme, nextColorSchemeVariant);
          } else {
            syncRenderedColorScheme(
              darkSchemeMediaQuery.current?.matches ? ColorScheme.Dark : ColorScheme.Light,
              nextColorSchemeVariant
            );
          }
        }
      }
    },
    [defaultColorScheme, defaultColorSchemeVariant, syncRenderedColorScheme]
  );

  const darkSchemeMediaQuery = useRef<MediaQueryList>();
  useEffect(() => {
    darkSchemeMediaQuery.current = matchMedia('(prefers-color-scheme: dark)');
  }, []);

  useEffect(() => {
    const onDarkSchemeMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
      syncRenderedColorScheme(matches ? ColorScheme.Dark : ColorScheme.Light);
    };

    if (colorScheme === ColorScheme.Auto) {
      // Safari < 14 does not support MQL events
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      darkSchemeMediaQuery.current?.addEventListener?.('change', onDarkSchemeMediaQueryChange);
    }

    return () => {
      if (colorScheme === ColorScheme.Auto) {
        // Safari < 14 does not support MQL events
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        darkSchemeMediaQuery.current?.removeEventListener?.('change', onDarkSchemeMediaQueryChange);
      }
    };
  }, [colorScheme, syncRenderedColorScheme]);

  useEffect(() => {
    if (isLocalStorageAccessible()) {
      const storedColorScheme = normalizeColorScheme(localStorage.getItem(COLOR_SCHEME_KEY), defaultColorScheme);
      const storedColorSchemeVariant = normalizeColorSchemeVariant(
        localStorage.getItem(COLOR_SCHEME_VARIANT_KEY),
        defaultColorSchemeVariant
      );

      setColorScheme(storedColorScheme);
      setColorSchemeVariant(storedColorSchemeVariant);

      if (storedColorScheme !== ColorScheme.Auto) {
        setRenderedColorScheme(storedColorScheme);
      } else {
        setRenderedColorScheme(darkSchemeMediaQuery.current?.matches ? ColorScheme.Dark : ColorScheme.Light);
      }
    }
  }, [defaultColorScheme, defaultColorSchemeVariant]);

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `document.documentElement.setAttribute('${COLOR_SCHEME_DATA_ATTRIBUTE}', localStorage.getItem('${COLOR_SCHEME_KEY}') || '${defaultColorScheme}');
if (document.documentElement.dataset.${COLOR_SCHEME_DATA_PROPERTY} === '${ColorScheme.Auto}') {
  document.documentElement.setAttribute('${COLOR_SCHEME_DATA_ATTRIBUTE}', matchMedia('(prefers-color-scheme: dark)').matches ? '${ColorScheme.Dark}' : '${ColorScheme.Light}');
}
if (!document.documentElement.dataset.${COLOR_SCHEME_VARIANT_DATA_PROPERTY}) {
  document.documentElement.setAttribute('${COLOR_SCHEME_VARIANT_DATA_ATTRIBUTE}', localStorage.getItem('${COLOR_SCHEME_VARIANT_KEY}') || '${defaultColorSchemeVariant}');
} else {
  localStorage.setItem('${COLOR_SCHEME_VARIANT_KEY}', document.documentElement.dataset.${COLOR_SCHEME_VARIANT_DATA_PROPERTY})
}
document.head.insertAdjacentHTML('beforeend', \`<meta name="twitter:widgets:theme" content="\${document.documentElement.dataset.${COLOR_SCHEME_DATA_PROPERTY}}" />\`);
document.head.insertAdjacentHTML('beforeend', \`<meta name="theme-color" content="\${document.documentElement.dataset.${COLOR_SCHEME_DATA_PROPERTY} === '${ColorScheme.Dark}' ? '${darkThemeColor}' : '${lightThemeColor}' }" />\`);`,
          },
        ]}
      />
      <ColorSchemeContext.Provider
        value={useMemo(() => [colorScheme, colorSchemeVariant], [colorScheme, colorSchemeVariant])}
      >
        <RenderedColorSchemeContext.Provider
          value={useMemo(() => [renderedColorScheme, colorSchemeVariant], [colorSchemeVariant, renderedColorScheme])}
        >
          <SetColorSchemeContext.Provider value={updateColorScheme}>{children}</SetColorSchemeContext.Provider>
        </RenderedColorSchemeContext.Provider>
      </ColorSchemeContext.Provider>
    </>
  );
};

const ColorSchemeContext = createContext<[ColorScheme | undefined, ColorSchemeVariant | undefined]>([
  undefined,
  undefined,
]);
const RenderedColorSchemeContext = createContext<
  [ColorScheme.Dark | ColorScheme.Light | undefined, ColorSchemeVariant | undefined]
>([undefined, undefined]);
const SetColorSchemeContext = createContext<
  (nextColorScheme: ColorScheme, nextColorSchemeVariant?: ColorSchemeVariant) => void
>(() => {});

function useColorScheme(): [ColorScheme | undefined, ColorSchemeVariant | undefined] {
  return useContext(ColorSchemeContext);
}

function useRenderedColorScheme(): [ColorScheme.Dark | ColorScheme.Light | undefined, ColorSchemeVariant | undefined] {
  return useContext(RenderedColorSchemeContext);
}

function useSetColorScheme(): (nextColorScheme: ColorScheme, nextColorSchemeVariant?: ColorSchemeVariant) => void {
  return useContext(SetColorSchemeContext);
}

function normalizeColorScheme(scheme: string | null, defaultColorScheme: ColorScheme): ColorScheme {
  if (scheme === ColorScheme.Light) return ColorScheme.Light;
  if (scheme === ColorScheme.Dark) return ColorScheme.Dark;
  return defaultColorScheme;
}

function normalizeColorSchemeVariant(schemeVariant: string | null, defaultColorSchemeVariant: ColorSchemeVariant) {
  if (schemeVariant === ColorSchemeVariant.Primary) return ColorSchemeVariant.Primary;
  if (schemeVariant === ColorSchemeVariant.Secondary) return ColorSchemeVariant.Secondary;
  return defaultColorSchemeVariant;
}

function isLocalStorageAccessible() {
  try {
    return typeof window.localStorage.getItem !== 'undefined';
  } catch {
    return false;
  }
}

export {
  ColorSchemeProvider,
  COLOR_SCHEME_VARIANT_KEY,
  COLOR_SCHEME_DATA_ATTRIBUTE,
  COLOR_SCHEME_VARIANT_DATA_ATTRIBUTE,
  COLOR_SCHEME_DATA_PROPERTY,
  COLOR_SCHEME_VARIANT_DATA_PROPERTY,
  ColorScheme,
  ColorSchemeVariant,
  useColorScheme,
  useRenderedColorScheme,
  useSetColorScheme,
};
export type { ColorSchemeProviderProps };
