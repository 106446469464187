import { ReactNode } from 'react';
import { CommentContent, CommentMediaStats } from '../components';
import { UniversalComment } from '../types';
import { TimeAgo } from '../../TimeAgo';
import { CommentDeleteButton } from '../components/CommentDeleteButton';

interface PlainCommentProps {
  comment: UniversalComment;
  onReceiverClick: () => void;
  customHeader?: ReactNode;
  className?: string;
}

const SimpleComment = ({ comment, className, customHeader, onReceiverClick }: PlainCommentProps) => (
  <div className={className}>
    <CommentContent comment={comment} customHeader={customHeader} hideHeader onReceiverClick={onReceiverClick} />
    <div className="mt-6 flex justify-between">
      <div className="flex gap-2">
        <CommentMediaStats className="" comment={comment} />
        <span className="self-start">
          <CommentDeleteButton comment={comment} />
        </span>
      </div>
      <TimeAgo className="shrink-0 self-end whitespace-nowrap text-sm" time={new Date(comment.createdAt)} />
    </div>
  </div>
);

export { SimpleComment };
