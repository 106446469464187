import { Outlet } from 'react-router';
import { Header } from '../Header';

interface LayoutProps {
  hideHeader?: boolean;
}

const Layout = ({ hideHeader }: LayoutProps): JSX.Element => (
  <>
    {!hideHeader ? <Header /> : null}
    <Outlet />
  </>
);

export { Layout };
