import { ReactNode } from 'react';
import noun from 'plural-ru';
import { UniversalComment } from '../types';
import { Button } from '../../Button';
import ArrowIcon from '../toggle.svg?svgr';

interface DeletedCommentProps {
  comment: UniversalComment;
  areChildrenVisible?: boolean;
  customHeader?: ReactNode;
  hideReplies?: boolean;
  toggleChildren?: (nextValue?: boolean) => void;
  onCancelNewComment?: () => void;
}

const DeletedComment = ({
  customHeader,
  comment,
  areChildrenVisible,
  onCancelNewComment,
  toggleChildren,
  hideReplies,
}: DeletedCommentProps) => {
  const childrenCount = comment.children?.length;
  const onToggleChildren = () => {
    if (areChildrenVisible) {
      onCancelNewComment?.();
    }
    toggleChildren?.();
  };

  return (
    <>
      {customHeader ? <div className="mb-3 flex items-start md:mb-6">{customHeader}</div> : null}
      <p>Комментарий был удален</p>
      {childrenCount && !hideReplies ? (
        <div className="mt-3 flex gap-2 text-sm md:mt-6 md:gap-4">
          <Button className="flex gap-1.5" onClick={onToggleChildren}>
            <ArrowIcon className={areChildrenVisible ? 'rotate-90' : 'rotate-0'} />
            {childrenCount} {noun(childrenCount, 'ответ', 'ответа', 'ответов')}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export { DeletedComment };
