import { UploadedImage } from './UploadedImage';
import {
  BuildUrlOptions,
  GetByUrlsResponse,
  ImageServiceClientOptions,
  UploadOptions,
  UploadResponse,
  ImageSize,
  UploadOptionsWithRequiredResponse,
  RequestOptions,
} from './types';
import { ApiError } from './ApiError';

export class ImageServiceClient {
  static getDefaultPreviewsString(defaultPreviews: (number | ImageSize)[]): string {
    return (
      defaultPreviews
        // number (width) - resize; object - crop
        .map((value) => (typeof value === 'object' ? `${value.width}x${value.height}` : value))
        .join(',')
    );
  }

  static async doRequest(url: string, options?: RequestInit): Promise<Response> {
    let response: Response;

    try {
      response = await fetch(url, options);
    } catch (error) {
      throw new ApiError({ response: error, code: 0, message: 'No network', isAborted: options?.signal?.aborted });
    }

    return response;
  }

  static async handleResponse(response: Response): Promise<unknown> {
    const isJson = response.headers.get('content-type')?.includes('application/json');

    const data: unknown = isJson ? await response.json() : response.status !== 204 ? await response.text() : undefined;

    if (!response.ok) {
      throw new ApiError({
        response: data,
        code: response.status,
        message: 'Response is not ok',
      });
    }

    return data;
  }

  private readonly baseUrl: string;

  private readonly basePath: string;

  constructor(opts: ImageServiceClientOptions) {
    this.baseUrl = opts.baseUrl;
    this.basePath = opts.basePath || '/image';
  }

  public async deleteByUrl(url: string): Promise<void> {
    const requestUrl = this.buildUrl({ params: { url } });

    const response = await ImageServiceClient.doRequest(requestUrl, { method: 'DELETE' });
    await ImageServiceClient.handleResponse(response);
  }

  public async delete(id: string): Promise<void> {
    const url = this.buildUrl({ path: `/${encodeURIComponent(id)}` });

    const response = await ImageServiceClient.doRequest(url, { method: 'DELETE' });
    await ImageServiceClient.handleResponse(response);
  }

  public async getByUrls(urls: string[], additionalFields?: 'fileSize'[]): Promise<GetByUrlsResponse> {
    const url = this.buildUrl({
      params: {
        id: urls.map(encodeURIComponent).join(','),
        return: additionalFields ? `addition:${additionalFields.map(encodeURIComponent).join(',')}` : undefined,
      },
    });

    const response = await ImageServiceClient.doRequest(url);

    return (await ImageServiceClient.handleResponse(response)) as GetByUrlsResponse;
  }

  public async copyOrUpload(
    fileOrUrl: File | Blob | string,
    opts: UploadOptionsWithRequiredResponse,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage>;
  public async copyOrUpload(
    fileOrUrl: File | Blob | string,
    opts: UploadOptions,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage | undefined>;
  public async copyOrUpload(
    fileOrUrl: File | Blob | string,
    opts: UploadOptions,
    requestOptions: RequestOptions = {}
  ): Promise<UploadedImage | undefined> {
    if (typeof fileOrUrl === 'string') {
      return this.copy(fileOrUrl, opts, requestOptions);
    }

    return this.upload(fileOrUrl, opts, requestOptions);
  }

  public async copy(
    copyFromUrl: string,
    { defaultPreviews, ...restOpts }: UploadOptionsWithRequiredResponse,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage>;
  public async copy(
    copyFromUrl: string,
    { defaultPreviews, ...restOpts }: UploadOptions,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage | undefined>;
  public async copy(
    copyFromUrl: string,
    { defaultPreviews, ...restOpts }: UploadOptions,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage | undefined> {
    const url = this.buildUrl({
      params: {
        ...restOpts,
        defaultPreviews: ImageServiceClient.getDefaultPreviewsString(defaultPreviews),
        copyFromUrl,
      },
    });

    const response = await ImageServiceClient.doRequest(url, { method: 'POST', signal: requestOptions?.signal });

    const responseData = (await ImageServiceClient.handleResponse(response)) as UploadResponse | undefined;

    if (responseData) {
      return new UploadedImage(responseData.image);
    }

    return undefined;
  }

  public upload(
    file: File | Blob,
    { defaultPreviews, ...restOpts }: UploadOptionsWithRequiredResponse,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage>;
  public upload(
    file: File | Blob,
    { defaultPreviews, ...restOpts }: UploadOptions,
    requestOptions?: RequestOptions
  ): Promise<UploadedImage | undefined>;
  public upload(
    file: File | Blob,
    { defaultPreviews, ...restOpts }: UploadOptions,
    requestOptions: RequestOptions = {}
  ): Promise<UploadedImage | undefined> {
    return new Promise((resolve, reject) => {
      try {
        const url = this.buildUrl({
          params: { ...restOpts, defaultPreviews: ImageServiceClient.getDefaultPreviewsString(defaultPreviews) },
        });

        const xhr = new XMLHttpRequest();
        const { signal } = requestOptions;

        signal?.addEventListener('abort', () => {
          xhr.abort();
        });

        xhr.upload.addEventListener('progress', (e) => {
          if (e.lengthComputable) {
            requestOptions.onProgress?.(e.loaded / e.total);
          }
        });

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 201) {
              const { image } = xhr.response as UploadResponse;
              resolve(new UploadedImage(image));
            } else if (xhr.status === 204) {
              resolve(undefined);
            } else {
              reject(
                new ApiError({
                  response: xhr.response,
                  code: xhr.status,
                  message: 'Response is not ok',
                  isAborted: signal?.aborted,
                })
              );
            }
          }
        };

        xhr.onerror = (event) => {
          reject(new ApiError({ response: event, code: 0, message: 'No network' }));
        };

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.responseType = 'json';
        xhr.send(file);
      } catch (error) {
        reject(error);
      }
    });
  }

  private buildUrl({ path = '', params = {} }: BuildUrlOptions = {}) {
    return `${this.baseUrl}${this.basePath}${path}?${Object.entries(params)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
      .join('&')}`;
  }
}
