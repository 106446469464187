import { useCallback } from 'react';
import { Clickable, ClickableProps } from '../../Clickable';
import { tw } from '../../../styles/tw';

interface ControlsProps {
  unviewedOnly: boolean;
  setUnviewedOnly: (value: boolean) => void;
}

const Controls = ({ unviewedOnly, setUnviewedOnly }: ControlsProps) => {
  const seeAll = useCallback(() => setUnviewedOnly(false), [setUnviewedOnly]);
  const seeViewed = useCallback(() => setUnviewedOnly(true), [setUnviewedOnly]);

  return (
    <div className="grid grid-cols-[1fr_1fr] gap-7">
      <FilterButton active={!unviewedOnly} onClick={seeAll}>
        Все
      </FilterButton>
      <FilterButton active={unviewedOnly} onClick={seeViewed}>
        Непрочитанные
      </FilterButton>
    </div>
  );
};

const FilterButton = ({ className, active, ...restProps }: ClickableProps & { active: boolean }) => (
  <Clickable
    className={tw(
      'relative border-b pb-3 hover:border-half-blood hover:text-half-blood',
      active ? 'border-blood text-blood' : 'border-text-primary text-text-primary',
      className
    )}
    {...restProps}
  />
);

export { Controls };
