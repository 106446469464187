import TimeAgo, { Step } from 'javascript-time-ago';
// eslint-disable-next-line import/no-internal-modules
import ru from 'javascript-time-ago/locale/ru';
/**
 * Dts-bundle-generator doesn't seem to work when types aren't explicitly imported. But when they are, webpack breaks if
 * type definition uses non-default export for some reason.
 */
/* eslint-disable import/no-internal-modules,@typescript-eslint/ban-ts-comment */
// @ts-ignore
import { round } from 'javascript-time-ago/steps';

TimeAgo.addDefaultLocale(ru);

TimeAgo.addLabels('ru', 'custom', {
  second: {
    past: {
      one: '{0} сек',
      other: '{0} сек',
    },
    future: {
      one: 'через {0} сек',
      other: 'через {0} сек',
    },
  },
  minute: {
    past: {
      one: '{0} мин',
      other: '{0} мин',
    },
    future: {
      one: 'через {0} мин',
      other: 'через {0} мин',
    },
  },
  hour: {
    past: {
      one: '{0} ч',
      other: '{0} ч',
    },
    future: {
      one: 'через {0} ч',
      other: 'через {0} ч',
    },
  },
  day: {
    past: {
      one: '{0} д',
      other: '{0} д',
    },
    future: {
      one: 'через {0} д',
      other: 'через {0} д',
    },
  },
  week: {
    past: {
      one: '{0} нед',
      other: '{0} нед',
    },
    future: {
      one: 'через {0} нед',
      other: 'через {0} нед',
    },
  },
  month: {
    past: {
      one: '{0} мес',
      other: '{0} мес',
    },
    future: {
      one: 'через {0} мес',
      other: 'через {0} мес',
    },
  },
  year: {
    past: {
      one: '{0} г',
      few: '{0} г',
      many: '{0} л',
    },
    future: {
      one: 'через {0} г',
      few: 'через {0} г',
      many: 'через {0} л',
    },
  },
});

const timeAgo = new TimeAgo('ru-RU');
const formatTimeAgo = (time: Date) =>
  timeAgo.format(time, {
    steps: round as Step[],
    labels: 'custom',
  });

export { formatTimeAgo };
