/** @deprecated Use autogenerated enum as soon as it's available */
export enum UserRole {
  Admin = 'ADMIN',
  Author = 'AUTHOR',
  /** @deprecated */
  AdminLegacy = 'Admin',
  /** @deprecated */
  AuthorLegacy = 'Author',
  Editor = 'EDITOR',
  /** @deprecated */
  EditorLegacy = 'Editor',
  Reader = 'READER',
  /** @deprecated */
  ReaderLegacy = 'Reader',
  SuperAdmin = 'SUPER_ADMIN',
  /** @deprecated */
  SuperAdminLegacy = 'SuperAdmin',
  Customer = 'CUSTOMER',
}

export enum UserPermission {
  Blogging = 'BLOGGING',
  /** @deprecated */
  BloggingLegacy = 'Blogging',
  CreateAdvertisement = 'CREATE_ADVERTISEMENT',
  /** @deprecated */
  CreateAdvertisementLegacy = 'CreateAdvertisement',
  ManageAdvertisement = 'MANAGE_ADVERTISEMENT',
  Moderate = 'MODERATE',
  /** @deprecated */
  ManageAdvertisementLegacy = 'ManageAdvertisement',
  /** @deprecated */
  ModerateLegacy = 'Moderate',
  PublishArticle = 'PUBLISH_ARTICLE',
  /** @deprecated */
  PublishArticleLegacy = 'PublishArticle',
  RequestEmail = 'REQUEST_EMAIL',
  /** @deprecated */
  RequestEmailLegacy = 'RequestEmail',
  SearchByEmail = 'SEARCH_BY_EMAIL',
  /** @deprecated */
  SearchByEmailLegacy = 'SearchByEmail',
  UpdateUser = 'UPDATE_USER',
  /** @deprecated */
  UpdateUserLegacy = 'UpdateUser',
  ViewRevenue = 'VIEW_REVENUE',
  /** @deprecated */
  ViewRevenueLegacy = 'ViewRevenue',
  WriteArticle = 'WRITE_ARTICLE',
  /** @deprecated */
  WriteArticleLegacy = 'WriteArticle',
  ViewUnpublished = 'VIEW_UNPUBLISHED',
}
