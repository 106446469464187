import { useOpenState } from '@flame-frontend-utils/commons';
import { createContext, useContext, ReactNode } from 'react';
import { Popup } from './Popup';
import { Button, ButtonVariant } from './Button';
import ArrowRightIcon from '../icons/arrow-right.svg?svgr';

interface ModerationPopupProviderProps {
  children?: ReactNode;
}

const ModerationPopupProvider = ({ children }: ModerationPopupProviderProps) => {
  const [isOpen, onOpen, onClose] = useOpenState(false);

  return (
    <OpenModerationPopupContext.Provider value={onOpen}>
      {children}
      <Popup isOpen={isOpen} onRequestClose={onClose}>
        <div className="m-auto max-w-2xl">
          <p className="mb-5.5">
            Посты, которые публикуются на нашем сайте, проходят модерацию. Мы стремимся создать безопасное и
            уважительное сообщество, поэтому проводим проверку постов на соответствие нашим правилам и стандартам.
          </p>
          <p>
            Мы стараемся проверять каждый пост как можно быстрее, но иногда это может занять некоторое время.
            Пожалуйста, будьте терпеливы, мы делаем все возможное, чтобы обеспечить быструю и эффективную модерацию.
          </p>
          <Button className="mt-8 w-full md:w-80" variant={ButtonVariant.Contained} onClick={onClose}>
            Ок
            <ArrowRightIcon className="ml-auto" />
          </Button>
        </div>
      </Popup>
    </OpenModerationPopupContext.Provider>
  );
};

const OpenModerationPopupContext = createContext<() => void>(() => {});

const useOpenModerationPopup = () => useContext(OpenModerationPopupContext);

export { ModerationPopupProvider, useOpenModerationPopup };
