export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function assertIsDefined<T>(value: T | null | undefined, message?: string): asserts value is T {
  if (!isDefined(value)) {
    throw new Error(`Assertion failed: Expected value to be defined.${message ? ` ${message}` : ''}`);
  }
}

export function getDefined<T>(value: T | null | undefined, message?: string): T {
  assertIsDefined(value, message);

  return value;
}
