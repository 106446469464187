import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const useLoadingStatus = (): boolean => {
  const status = useApolloNetworkStatus();
  return status.numPendingMutations > 0 || status.numPendingQueries > 0;
};

export { link as apolloNetworkStatusLink, useApolloNetworkStatus, useLoadingStatus };
