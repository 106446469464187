import * as Sentry from '@sentry/react';
import { ErrorEvent } from '@sentry/types';
import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: PUBLIC_CONFIG.SENTRY_DSN,
  release: String(BUILD_TIME),
  environment: PUBLIC_CONFIG.APP_ENV,
  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: PUBLIC_CONFIG.APP_ENV === 'production' ? 0.1 : 1.0,
  replaysSessionSampleRate: PUBLIC_CONFIG.APP_ENV === 'production' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    /** @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120 */
    'ResizeObserver loop limit exceeded',
    /** @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120 */
    'ResizeObserver loop completed with undelivered notifications.',
    // Most likely caused by ads code
    /^AbortError: The play\(\) request was interrupted.*/,
    'AbortError: The operation was aborted.',
    'NotSupportedError: Failed to load because no supported source was found.',
    'NotSupportedError: The element has no supported sources.',
    "Failed to execute 'evaluate' on 'Document': The string '' is not a valid XPath expression.",
    'Non-Error promise rejection captured with value: undefined',
    'Unexpected token \'m\', "msg" is not valid JSON',
    /.*\{"jsth":"spletnik\.ru"}.*/,
  ],
  beforeSend(event) {
    if (!isAdByStackTrace(event) && !isXhrEvent(event) && !isNetworkConnectivityError(event) && !isAdMessage(event)) {
      return event;
    }

    return null;
  },
});

function isAdByStackTrace(event: ErrorEvent) {
  try {
    const stacktraceFrames = event.exception?.values?.[0]?.stacktrace?.frames ?? [];

    return stacktraceFrames.some(
      ({ filename }) =>
        filename?.includes('yandex.ru/ads') ||
        filename?.includes('yastatic.net') ||
        filename?.includes('viqeo.tv') ||
        filename?.includes('buzzoola.com') ||
        filename?.includes('afp.ai') ||
        filename?.includes('googlesyndication.com') ||
        filename?.includes('imasdk.googleapis.com') ||
        filename?.includes('s0.2mdn.net') ||
        filename?.includes('banners.adfox.ru') ||
        filename?.includes('weborama.fr') ||
        filename?.includes('adx.com.ru') ||
        filename?.includes('videonow.ru') ||
        filename?.includes('adriver.ru') ||
        filename?.includes('verstka.org') ||
        filename?.includes('adlooxtracking.ru')
    );
  } catch {
    // Fallback to false
  }

  return false;
}

function isXhrEvent(event: ErrorEvent) {
  try {
    // eslint-disable-next-line no-underscore-dangle
    const rawEventData = event.extra?.__serialized__;

    return (
      isRecord(rawEventData) &&
      typeof rawEventData.target === 'string' &&
      rawEventData.target.includes('XMLHttpRequest')
    );
  } catch {
    // Fallback to false
  }

  return false;
}

function isNetworkConnectivityError(event: ErrorEvent) {
  try {
    const value = event.exception?.values?.[0]?.value;

    return value?.includes('Failed to fetch') || value?.includes('Load failed');
  } catch {
    // Fallback to false
  }

  return false;
}

function isAdMessage(event: ErrorEvent) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const message = event.message ? JSON.parse(event.message) : null;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    return Boolean(message[message.length - 1].jsth.includes('spletnik.ru'));
  } catch {
    // Fallback to false
  }

  return false;
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
