import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import ModalLib, { Props } from 'react-modal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

ModalLib.setAppElement('#root');

interface ModalProps extends Props {
  allowScroll?: boolean;
}

const Modal = ({ contentRef, allowScroll, ...props }: ModalProps): JSX.Element => {
  const [element, setElement] = useState<HTMLDivElement>();

  const contentRefFn = (node: HTMLDivElement) => {
    setElement(node);
    contentRef?.(node);
  };

  useEffect(() => {
    if (element && !allowScroll) {
      disableBodyScroll(element, { reserveScrollBarGap: true });
    }

    return () => {
      if (element && !allowScroll) {
        enableBodyScroll(element);
      }
    };
  }, [element, allowScroll]);

  return <ModalLib {...props} contentRef={contentRefFn} />;
};

export { Modal };
export type { ModalProps };
