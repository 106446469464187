import { isDefined } from '@flame-frontend-utils/commons';
import View from './icons/view.svg?svgr';
import Comment from './icons/comment.svg?svgr';
import Like from './icons/like.svg?svgr';
import Dislike from './icons/dislike.svg?svgr';
import Ton from './icons/ton.svg?svgr';
import { StatsIcon } from './StatsIcon';
import { Button, ButtonVariant } from '../Button';
import { tw } from '../../styles/tw';

interface MediaStatsProps {
  comments?: number;
  totalTonAmount?: number;
  likes?: number;
  dislikes?: number;
  views?: number;
  className?: string;
  scrollToId?: string;
}

const MediaStats = ({ likes, dislikes, comments, totalTonAmount, views, scrollToId, className }: MediaStatsProps) => {
  const onClick = () => {
    if (scrollToId) {
      document.getElementById(scrollToId)?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
    }
  };

  const RootComponent = scrollToId ? Button : 'div';

  return (
    <RootComponent
      className={tw('flex gap-2 text-blood md:gap-2.5', className)}
      onClick={scrollToId ? onClick : undefined}
      variant={ButtonVariant.Underlined}
    >
      {isDefined(views) ? <StatsIcon Icon={View} counter={views} hideZero /> : null}
      {isDefined(likes) ? <StatsIcon Icon={Like} counter={likes} /> : null}
      {isDefined(dislikes) ? <StatsIcon Icon={Dislike} counter={dislikes} /> : null}
      {isDefined(comments) ? <StatsIcon Icon={Comment} counter={comments} hideZero /> : null}
      {isDefined(totalTonAmount) ? <StatsIcon Icon={Ton} counter={totalTonAmount} hideZero /> : null}
    </RootComponent>
  );
};

export { MediaStats };
