import { useIsClientSide } from '@flame-frontend-utils/commons';
import { formatTimeAgo } from '../../lib/timeAgo';

interface TimeAgoProps {
  time: Date;
  className?: string;
}

const TimeAgo = ({ time, className }: TimeAgoProps) => {
  const isClientSide = useIsClientSide();

  return isClientSide ? <p className={className}>{formatTimeAgo(time)}</p> : null;
};

export { TimeAgo };
