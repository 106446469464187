import { useState } from 'react';
import { areDepsEqual } from './areDepsEqual';

export function useDerivedState(cb: (isInitialRender: boolean) => void, deps: unknown[]): void {
  const [prevDeps, setPrevDeps] = useState<unknown[] | null>(null);

  if (prevDeps === null || !areDepsEqual(prevDeps, deps)) {
    cb(prevDeps === null);
    setPrevDeps(deps);
  }
}
