import { useEffect, RefObject, useRef } from 'react';
import { HEADER_HEIGHT } from '../../styles/fixedSizes';
import { AdvertisementId } from './constants';
import { useOnView } from './AdvertisementViewReporter';

const SEEN_WAIT = 5000; // ms

function useReportView(rootRef: RefObject<HTMLElement>, id: AdvertisementId) {
  const onView = useOnView();

  const isViewedRef = useRef(false);

  useEffect(() => {
    const rootElement = rootRef.current;
    let seenTimeoutId: ReturnType<typeof setTimeout> | undefined;

    const onIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        // For some reason, sometimes entries do not contain exactly one element (rootElement).
        if (entry.target === rootElement) {
          if (entry.isIntersecting) {
            seenTimeoutId = setTimeout(() => {
              if (!isViewedRef.current) {
                onView?.(id);
                isViewedRef.current = true;
              }
            }, SEEN_WAIT);
          } else if (seenTimeoutId) {
            clearTimeout(seenTimeoutId);
          }
        }
      });
    };

    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.01,
      rootMargin: `-${HEADER_HEIGHT}px 0px 0px 0px`,
    });
    if (rootElement && !isViewedRef.current && onView) {
      observer.observe(rootElement);
    }

    return () => {
      observer.disconnect();
      if (seenTimeoutId) {
        clearTimeout(seenTimeoutId);
      }
    };
  }, [id, onView, rootRef]);
}

export { useReportView };
