import { ButtonVariant, Button } from '../../components/Button';

const AdvertisingContent = () => (
  <>
    <p>
      Spletnik обладает широкими рекламными возможностями — на нашей площадке можно не только размещать баннеры, но и
      создавать различные спецпроекты. При этом мы действительно заботимся об эффективности рекламы и всегда предлагаем
      решения, которые работают.
    </p>

    <p>
      По вопросам сотрудничества и рекламе:{' '}
      <Button variant={ButtonVariant.InlineText} to="mailto:adv.spletnik@redefine.family">
        adv.spletnik@redefine.family
      </Button>
    </p>

    <p>
      Programmatic сделки, открытый аукцион медийных форматов:{' '}
      <Button variant={ButtonVariant.InlineText} to="mailto:adtech@redefine.family">
        adtech@redefine.family
      </Button>
    </p>

    <p>СКАЧАТЬ МАТЕРИАЛЫ:</p>

    <ul>
      <li>
        <Button
          variant={ButtonVariant.InlineText}
          to="https://docs.google.com/spreadsheets/d/1VWSNEHwWAizWMrJYtiY3zDwywtIA_l_2Mng9KpusAC8"
        >
          Прайс лист 2024
        </Button>
      </li>
      <li>
        <Button isExternal variant={ButtonVariant.InlineText} to="/Mediakit_Spletnik_2022.pdf">
          Медиа-кит
        </Button>
      </li>
    </ul>
  </>
);

export { AdvertisingContent };
