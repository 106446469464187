declare global {
  interface Ya {
    adfoxCode?: AdFoxCode;
  }

  interface AdFoxCode {
    destroy?: (id: string) => void;
  }

  interface Window {
    Ya?: Ya;
  }
}

function dismissFloatingAds() {
  if (typeof window !== 'undefined') {
    window.Ya?.adfoxCode?.destroy?.('adfox_166748962668051237');
  }
}

export { dismissFloatingAds };
