import { useMutation } from '@apollo/client';
import { ReactNode } from 'react';
import noun from 'plural-ru';
import Restore from './restore.svg?svgr';
import { Button } from '../../Button';
import { RestoreCommentDocument } from './gql/RestoreComment.document';
import { CommentContent } from '../components';
import { UniversalComment } from '../types';
import { ignoreReject } from '../../../lib/ignoreReject';
import ArrowIcon from '../toggle.svg?svgr';

interface CommentProps {
  comment: UniversalComment;
  areChildrenVisible?: boolean;
  customHeader?: ReactNode;
  hideHeader?: boolean;
  hideReplies?: boolean;
  toggleChildren?: (nextValue?: boolean) => void;
  onCancelNewComment?: () => void;
}

const RestorableComment = ({
  comment,
  customHeader,
  areChildrenVisible,
  hideHeader,
  hideReplies,
  onCancelNewComment,
  toggleChildren,
}: CommentProps) => {
  const { id } = comment;

  const [restore, { loading }] = useMutation(RestoreCommentDocument, { variables: { id } });

  const childrenCount = comment.children?.length;
  const onToggleChildren = () => {
    if (areChildrenVisible) {
      onCancelNewComment?.();
    }
    toggleChildren?.();
  };

  return (
    <>
      <div className="mb-10 opacity-50">
        <CommentContent comment={comment} hideHeader={hideHeader} customHeader={customHeader} />
      </div>
      <div className="flex justify-between">
        <p>{loading ? 'Восстанавливаем...' : 'Комментарий был удален'}</p>
        <Button onClick={() => ignoreReject(restore())} disabled={loading}>
          <Restore />
        </Button>
      </div>
      {childrenCount && !hideReplies ? (
        <div className="mt-3 flex gap-2 text-sm md:mt-6 md:gap-4">
          <Button className="flex gap-1.5" onClick={onToggleChildren}>
            <ArrowIcon className={areChildrenVisible ? 'rotate-90' : 'rotate-0'} />
            {childrenCount} {noun(childrenCount, 'ответ', 'ответа', 'ответов')}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export { RestorableComment };
