/* eslint-disable no-console */
import { ROUTES } from './ROUTES';

if (PUBLIC_CONFIG.APP_ENV === 'development' && !SSR_MODE) {
  console.groupCollapsed('🔥 Dev/QA Info');
  console.log('The app was built at', new Date(BUILD_TIME).toString());
  console.log(`Visit ${window.location.origin}${ROUTES.COMPONENT_SHOWCASE.path} to see available components`);
  console.log('Public config:', PUBLIC_CONFIG);
  console.groupEnd();
}

export {};
