/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CommentFragmentFragmentDoc } from '../../../../../gql/CommentFragment.document';
export type UpdateCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  body: Types.Scalars['String'];
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'Comment', id: string, createdAt: number | string, body: string, parent?: { __typename?: 'Comment', id: string } | null, post: { __typename?: 'MediaPost', slug: string, searchParams: string, title: string, cover?: { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null } | null }, deleted?: { __typename?: 'CommentDeleted', deletedAt: number | string } | null, author: { __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }, reactions: { __typename?: 'Reactions', likesCount: number, dislikesCount: number, myReaction: Types.Reaction }, replyTo?: { __typename?: 'Comment', id: string, author: { __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }, parent?: { __typename?: 'Comment', id: string } | null } | null } };


export const UpdateCommentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateComment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"body"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateComment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"body"},"value":{"kind":"Variable","name":{"kind":"Name","value":"body"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CommentFragment"}}]}}]}},...CommentFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateCommentMutation, UpdateCommentMutationVariables>;