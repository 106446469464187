import * as yup from 'yup';

const schema = yup
  .object({
    from: yup.string().trim().email('Неверный формат email').required('Необходимо ввести email'),
    message: yup.string().trim().required('Необходимо ввести сообщение'),
    image: yup.string().trim(),
    captchaToken: yup.string().typeError('Похоже, что вы робот').trim().required('Похоже, что вы робот'),
  })
  .required('Поля обязательны для заполнения');

export { schema };
