import { getResizedImageUrl, getResizedImageSrcSet } from '@flame-frontend-utils/tiptap';
import { tw } from '../../styles/tw';

type Props = {
  name: string;
  image: { originalUrl: string; cropSettings?: unknown } | null | undefined;
  size?: number;
  alt?: string;
  className?: string;
};

declare module 'react' {
  interface CSSProperties {
    '--avatar-size'?: string;
  }
}

const Avatar = ({ size = 42, image, name, alt, className }: Props) =>
  image ? (
    <img
      className={tw(avatarStyles, className)}
      style={{ '--avatar-size': `${size / 16}rem` }}
      srcSet={getResizedImageSrcSet({
        resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
        originalUrl: image.originalUrl,
        cropSettings: image.cropSettings,
        width: size,
        height: size,
        fit: 'cover',
      })}
      src={getResizedImageUrl({
        resizeOrigin: PUBLIC_CONFIG.IMAGE_RESIZE_ORIGIN,
        originalUrl: image.originalUrl,
        cropSettings: image.cropSettings,
        width: size,
        height: size,
        fit: 'cover',
      })}
      alt={alt || 'Аватар'}
    />
  ) : (
    <div className={tw(avatarStyles, 'bg-blood', className)} style={{ '--avatar-size': `${size / 16}rem` }}>
      {name[0]?.toUpperCase()}
    </div>
  );

const avatarStyles = tw(
  'ml-2 flex h-[var(--avatar-size)] w-[var(--avatar-size)] shrink-0 items-center justify-center rounded-full object-cover text-[1.125rem] text-cream'
);

export { Avatar };
