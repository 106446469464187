/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FullCoverFragment = { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null };

export const FullCoverFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FullCover"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Cover"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"originalUrl"}},{"kind":"Field","name":{"kind":"Name","value":"alt"}},{"kind":"Field","name":{"kind":"Name","value":"cropSettings"}},{"kind":"Field","name":{"kind":"Name","value":"source"}}]}}]} as unknown as DocumentNode<FullCoverFragment, unknown>;