import { BrowserRouterProps } from 'react-router-dom';
import { ComponentType } from 'react';
import { StaticRouterProps } from 'react-router-dom/server';

let RequiredRouter: ComponentType<StaticRouterProps | BrowserRouterProps>;

if (SSR_MODE) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,global-require
  RequiredRouter = require('react-router-dom/server').StaticRouter;
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,global-require
  RequiredRouter = require('react-router-dom').BrowserRouter;
}

export const Router = RequiredRouter;
