import { Button, ButtonVariant } from '../../components/Button';
import { ROUTES } from '../../lib/ROUTES';
import { AgeRestrictionPlaque } from '../../components/AgeRestrictionPlaque';

const AboutContent = () => (
  <>
    <p>
      {PUBLIC_CONFIG.APP_NAME} — онлайн-издание об известных людях, их мнениях и поступках. Мы рассказываем об
      актуальных дискуссиях, важных событиях и образе жизни ярких, интересных и знаменитых.
    </p>

    <p>
      Важная часть проекта — блоги. Здесь зарегистрированные пользователи не только создают собственные публикации, но и
      общаются друг с другом. Редакция оставляет за собой право удалять материалы, нарушающие законодательство РФ. Мы
      также выступаем против всех видов дискриминации и ксенофобии.
    </p>

    <p>
      Редакция сайта не занимается коммерческими проектами. Все рекламные материалы маркируются, над ними работает
      отдельная команда.
    </p>

    <p>Использование материалов редакции разрешено только с согласия правообладателей.</p>

    <p>
      Мы используем cookie, чтобы собирать статистику, разнообразить контент, а также для отображения более релевантной
      рекламы.
    </p>

    <p>
      По всем вопросам обращайтесь в редакцию на{' '}
      <Button variant={ButtonVariant.InlineText} to="mailto:spletnik.info@spletnik.ru">
        spletnik.info@spletnik.ru
      </Button>
      .
    </p>
    <div className="pt-10" />
    <div className="inset-x-5 bottom-[10px] flex flex-col gap-2 border-t border-blood pt-4 md:inset-x-8 lg:absolute">
      <Button variant={ButtonVariant.InlineText} to={ROUTES.PRIVACY.buildPath({})} className="text-left">
        Политика конфиденциальности
      </Button>
      <Button variant={ButtonVariant.InlineText} to={ROUTES.AGREEMENT.buildPath({})} className="text-left">
        Пользовательское соглашение
      </Button>
    </div>
    <AgeRestrictionPlaque />
  </>
);

export { AboutContent };
