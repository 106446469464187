import { useOpenState } from '@flame-frontend-utils/commons';
import { Button, ButtonColor } from '../../Button';
import { Sidebar } from '../../Sidebar';
import { BottomSection } from './BottomSection';
import { MainSection } from './MainSection';
import { Feedback } from '../../FeedbackForm';
import { tw } from '../../../styles/tw';
import Cross from './cross.svg?svgr';

interface Props {
  onClose: () => void;
}

const Menu = ({ onClose }: Props): JSX.Element => {
  const [isFeedbackOpen, onFeedbackOpen, onFeedbackClose] = useOpenState();

  return (
    <nav className="flex grow flex-col bg-blood px-4 pb-7 pt-11 text-grey md:w-[33.875rem]">
      <Button className="absolute right-7 top-7" color={ButtonColor.Secondary} onClick={onClose}>
        <Cross />
      </Button>
      <MainSection closeMenu={onClose} />
      <BottomSection onClick={onClose} openFeedback={onFeedbackOpen} />
      <Sidebar overlayClassName={tw('bg-overlay/30')} isOpen={isFeedbackOpen} onRequestClose={onFeedbackClose}>
        <Feedback onClose={onFeedbackClose} />
      </Sidebar>
    </nav>
  );
};

export { Menu };
