import { CSSProperties } from 'react';
import { ButtonColor } from './types';

declare module 'react' {
  interface CSSProperties {
    '--base-color'?: string;
    '--accent-color'?: string;
    '--muted-color'?: string;
  }
}

enum ColorCssVariable {
  Base = '--base-color',
  Accent = '--accent-color',
  Muted = '--muted-color',
}

const colorsPrimary = {
  '--base-color': 'rgb(var(--color-cream))',
  '--accent-color': 'rgb(var(--color-blood))',
  '--muted-color': 'rgb(var(--color-half-blood))',
};

const colorsSecondary = {
  '--base-color': 'rgb(var(--color-cream))',
  '--accent-color': 'rgb(var(--color-text-primary))',
  '--muted-color': 'rgb(var(--color-text-secondary))',
};

const colorsTertiary = {
  '--base-color': 'rgb(var(--color-blood))',
  '--accent-color': 'rgb(var(--color-cream))',
  '--muted-color': 'rgb(var(--color-half-blood))',
};

function getColorStyles(variant: ButtonColor): CSSProperties {
  switch (variant) {
    case ButtonColor.Secondary:
      return colorsTertiary;
    case ButtonColor.Tertiary:
      return colorsSecondary;
    case ButtonColor.Primary:
    default:
      return colorsPrimary;
  }
}

export { getColorStyles, ColorCssVariable };
