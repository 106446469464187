import { useCallback } from 'react';
import Like from './icons/like.svg?svgr';
import Dislike from './icons/dislike.svg?svgr';
import LikeOutlined from './icons/like-outlined.svg?svgr';
import DislikeOutlined from './icons/dislike-outlined.svg?svgr';
import { Button, ButtonVariant } from '../Button';
import { Reaction } from '../../lib/graphql.document';
import { tw } from '../../styles/tw';

interface MediaStatsProps {
  likes: number;
  dislikes: number;
  personalReaction: Reaction;
  onReact: (reaction: Reaction) => void;
  isComment?: boolean;
  disabled?: boolean;
  className?: string;
}

const MediaStatsActionable = ({
  likes,
  dislikes,
  personalReaction,
  onReact,
  isComment,
  disabled,
  className,
}: MediaStatsProps) => {
  const withPersonalLike = personalReaction === Reaction.Like;
  const withPersonalDislike = personalReaction === Reaction.Dislike;

  const onLikeClick = useCallback(() => {
    onReact(withPersonalLike ? Reaction.None : Reaction.Like);
  }, [onReact, withPersonalLike]);

  const onDislikeClick = useCallback(() => {
    onReact(withPersonalDislike ? Reaction.None : Reaction.Dislike);
  }, [onReact, withPersonalDislike]);

  return (
    <div className={tw('flex scroll-mt-[calc(var(--header-height)+1.125rem)] gap-2 md:gap-4', className)}>
      <Button variant={ButtonVariant.Text} onClick={onLikeClick} disabled={disabled}>
        <span className={tw(isComment && 'hidden md:inline')}>Лайк</span>
        {withPersonalLike ? <Like className={svgStyle(isComment)} /> : <LikeOutlined className={svgStyle(isComment)} />}
        <div className="min-w-[1.25rem] text-left text-blood">{likes}</div>
      </Button>
      <Button variant={ButtonVariant.Text} onClick={onDislikeClick} disabled={disabled}>
        <span className={tw(isComment && 'hidden md:inline')}>Дизлайк</span>
        {withPersonalDislike ? (
          <Dislike className={svgStyle(isComment)} />
        ) : (
          <DislikeOutlined className={svgStyle(isComment)} />
        )}
        <div className="min-w-[1.25rem] text-left text-blood">{dislikes}</div>
      </Button>
    </div>
  );
};

const svgStyle = (isComment?: boolean) => (isComment ? tw('ml-0 mr-1.5 h-3 w-3 md:ml-1.5') : tw('mx-1.5 h-5 w-5'));

export { MediaStatsActionable };
