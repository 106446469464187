import { createContext, ReactNode, RefObject, useContext, useEffect, useRef } from 'react';

interface HydrateStatusRefProviderProps {
  children: ReactNode;
}

const HydrateStatusRefProvider = ({ children }: HydrateStatusRefProviderProps): JSX.Element => {
  const isHydratedRef = useRef(false);

  useEffect(() => {
    isHydratedRef.current = true;

    return () => {
      isHydratedRef.current = false;
    };
  }, []);

  return <IsHydratedRefContext.Provider value={isHydratedRef}>{children}</IsHydratedRefContext.Provider>;
};

const IsHydratedRefContext = createContext<RefObject<boolean>>({ current: true });

const useIsHydratedRef = (): RefObject<boolean> => useContext(IsHydratedRefContext);

export { HydrateStatusRefProvider, useIsHydratedRef };
