import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';
import { schema } from './validation';
import { SendMessageToSupportDocument } from './gql/SendMessageToSupport.document';
import { FeedbackFormState } from './types';
import { ignoreReject } from '../../lib/ignoreReject';

export const useFeedbackForm = (onClose: () => void) => {
  const { user } = useLoginInfo();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<FeedbackFormState>({
    defaultValues: {
      from: user?.email || '',
      message: '',
      image: '',
      captchaToken: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [mutate, { loading }] = useMutation(SendMessageToSupportDocument);

  const onSubmit = handleSubmit((formData: FeedbackFormState) => {
    void ignoreReject(
      mutate({
        variables: {
          dto: formData,
        },
      }).then(() => {
        onClose();
      })
    );
  });

  return { control, loading, errors, setError, onSubmit, disabled: !isValid || !isDirty || loading };
};
