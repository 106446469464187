import { Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { SidebarHeader } from '../SidebarHeader';
import { useFeedbackForm } from './useFeedbackForm';
import { TextInput } from '../TextInput';
import { Button, ButtonVariant } from '../Button';
import { FeedbackImageUpload } from './FeedbackImageUpload';
import { AutosizedTextarea } from '../AutosizedTextarea';

interface FeedbackFormProps {
  onClose: () => void;
}

const Feedback = ({ onClose }: FeedbackFormProps) => {
  const { errors, disabled, loading, onSubmit, control, setError } = useFeedbackForm(onClose);

  return (
    <section className="flex w-full grow flex-col bg-cream px-8 py-5 md:w-[33.875rem]">
      <SidebarHeader title="Обратная связь" onClose={onClose} />
      <form onSubmit={onSubmit}>
        <Controller
          control={control}
          name="from"
          render={({ field }) => (
            <TextInput className="[&:not(:last-of-type)]:border-b-0" {...field} placeholder="Почта" />
          )}
        />
        {errors.from?.message && <div className="mt-3">{errors.from.message}</div>}
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <AutosizedTextarea
              className="-ml-px border-b border-blood px-0 py-3 outline-none [&>::placeholder]:text-half-blood [&>textarea]:outline-none"
              {...field}
              placeholder="Сообщение"
            />
          )}
        />
        {errors.message?.message && <div className="mt-3">{errors.message.message}</div>}
        <Controller
          control={control}
          name="image"
          render={({ field: { ref: __, value, ...rest } }) => (
            <FeedbackImageUpload {...rest} currentFile={value || ''} setError={setError} />
          )}
        />
        {errors.image?.message && <div className="mt-3">{errors.image.message}</div>}
        <Controller
          control={control}
          render={({ field }) => (
            <ReCAPTCHA
              className="mt-12"
              {...field}
              onChange={(token) => {
                if (token !== null) {
                  field.onChange(token);
                }
              }}
              sitekey={PUBLIC_CONFIG.RECAPTCHA_SITE_KEY}
            />
          )}
          name="captchaToken"
        />
        {errors.captchaToken?.message && <div className="mt-3">{errors.captchaToken.message}</div>}
        <Button className="mt-14 w-full" disabled={disabled} type="submit" variant={ButtonVariant.Contained}>
          {loading ? 'Отправляем' : 'Отправить'}
        </Button>
      </form>
    </section>
  );
};

export { Feedback };
