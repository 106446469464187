import noun from 'plural-ru';
import { useState } from 'react';
import { useTimeout, useDerivedState } from '@flame-frontend-utils/commons';
import { CommentContent, CommentMediaStats } from '../components';
import { Button, ButtonVariant } from '../../Button';
import ArrowIcon from '../toggle.svg?svgr';
import { UniversalComment } from '../types';
import EditIcon from '../edit.svg?svgr';
import { useLoginInfo } from '../../../react-hooks/useLoginInfo';
import { hasPermission } from '../../../lib/apollo';
import { CurrentUserFragmentFragment } from '../../../gql/CurrentUserFragment.document';
import { UserPermission } from '../../../lib/graphql.document';

interface PlainCommentProps {
  comment: UniversalComment;
  areChildrenVisible: boolean;
  isReplyFormVisible: boolean;
  hideHeader?: boolean;
  toggleReply: () => void;
  onCancelNewComment: () => void;
  toggleChildren: (nextValue?: boolean) => void;
}

interface TimerProps {
  comment: UniversalComment;
  onEnd: () => void;
  user: CurrentUserFragmentFragment | null | undefined;
}

const EDIT_TIME = 1000 * 60 * 60;

const RespondableComment = ({
  comment,
  areChildrenVisible,
  isReplyFormVisible,
  hideHeader,
  onCancelNewComment,
  toggleReply,
  toggleChildren,
}: PlainCommentProps) => {
  const childrenCount = comment.children?.length;
  const onToggleChildren = () => {
    if (areChildrenVisible) {
      onCancelNewComment();
    }
    toggleChildren();
  };

  const { data: userData } = useLoginInfo();

  const [editing, setEditing] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState(() =>
    Boolean(getEditSecondsLeft(comment, userData?.loginInfo.user))
  );

  useDerivedState(() => {
    setEditButtonVisible(Boolean(getEditSecondsLeft(comment, userData?.loginInfo.user)));
  }, [comment, userData?.loginInfo.user]);

  const handleTimerEnd = () => {
    setEditing(false);
    setEditButtonVisible(false);
  };

  return (
    <>
      <CommentContent
        hideHeader={hideHeader}
        comment={comment}
        editing={editing}
        onEditComplete={() => setEditing(false)}
      />
      <div className="mt-3 flex gap-2 text-sm md:mt-6 md:gap-4">
        {childrenCount ? (
          <Button className="flex gap-1.5" onClick={onToggleChildren}>
            <ArrowIcon className={areChildrenVisible ? 'rotate-90' : 'rotate-0'} />
            {childrenCount} {noun(childrenCount, 'ответ', 'ответа', 'ответов')}
          </Button>
        ) : null}
        <CommentMediaStats comment={comment} />
        {!editing ? (
          <Button
            onClick={() => {
              toggleChildren(true);
              toggleReply();
            }}
          >
            {isReplyFormVisible ? 'Отменить' : 'Ответить'}
          </Button>
        ) : null}
        {!isReplyFormVisible && editButtonVisible ? (
          <Button onClick={() => setEditing(!editing)} variant={ButtonVariant.Underlined}>
            {editing ? (
              <span className="mr-1">Отменить</span>
            ) : (
              <>
                <span className="mr-1 hidden md:block">Редактировать</span>
                <EditIcon />
              </>
            )}
            <Timer comment={comment} user={userData?.loginInfo.user} onEnd={handleTimerEnd} />
          </Button>
        ) : null}
      </div>
    </>
  );
};

const Timer = ({ comment, user, onEnd }: TimerProps) => {
  const [time, setTime] = useState(() => getEditSecondsLeft(comment, user));

  useTimeout(
    () => {
      const nextTime = time - 1;
      setTime(nextTime);

      if (nextTime <= 0) {
        onEnd();
      }
    },
    time === Infinity ? Infinity : 1000,
    { repeating: true }
  );

  return (
    <p className="ml-1 tabular-nums">
      {time === Infinity ? '∞' : new Date(time * 1000).toISOString().substring(14, 19)}
    </p>
  );
};

function getEditSecondsLeft(comment: UniversalComment, user: CurrentUserFragmentFragment | null | undefined) {
  if (user) {
    if (hasPermission(user.permissions, UserPermission.Moderate)) {
      return Infinity;
    }

    if (user.id === comment.author.id) {
      return Math.floor(
        Math.max(0, EDIT_TIME - Math.max(0, Date.now() - new Date(comment.createdAt).getTime())) / 1000
      );
    }
  }

  return 0;
}

export { RespondableComment };
