import { Dispatcher } from '@flame-frontend-utils/commons';

export class AuthDispatcher extends Dispatcher<void> {
  private static instance: AuthDispatcher | undefined;

  public static getInstance(): AuthDispatcher {
    if (!SSR_MODE) {
      return AuthDispatcher.instance || (AuthDispatcher.instance = new AuthDispatcher());
    }

    throw new Error('AuthDispatcher can only be used on the client side');
  }

  private constructor() {
    super();
  }
}
