import { ErrorResponse } from '@apollo/client/link/error';
import { getGqlErrors, isNetworkError, someGqlErrorIs } from '@flame-frontend-utils/commons-apollo';
import { captureException, withScope } from '@sentry/react';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';
import { sha256 } from 'crypto-hash';
// eslint-disable-next-line import/no-internal-modules
import { print } from 'graphql/language/printer';
import { AuthDispatcher } from '../authDispatcher';
import { GqlError } from '../graphql.document';
import { ApolloContext } from './ApolloContext';

interface GlobalErrorHandlerOptions {
  context?: ApolloContext;
  location?: string;
}

const NETWORK_ERROR_MESSAGE = 'Ошибка сети. Проверьте подключение к интернету.';
const DEFAULT_ERROR_MESSAGE = 'Что-то пошло не так. Мы уже работаем над этим.';

const APP_ERROR_MAP: Record<GqlError, string> = {
  [GqlError.AdvertisementNotFound]: 'Реклама не найдена.',
  [GqlError.CantModifyAfterPublication]: 'Нельзя редактировать после публикации.',
  [GqlError.ChangeNicknameTooOften]: 'Нельзя менять имя слишком часто.',
  [GqlError.ChangeAliasTooOften]: 'Нельзя менять ссылку слишком часто.',
  [GqlError.CommentNotFound]: 'Комментарий не найден.',
  [GqlError.ConfirmationCodeNotMatches]: 'Неверный код.',
  [GqlError.ContentNotFound]: 'Контент не найден.',
  [GqlError.Forbidden]: 'Вы не можете совершить это действие.',
  [GqlError.InvalidCredentials]: 'Неправильные данные для входа.',
  [GqlError.PasswordNotSet]: 'Не установлен пароль. Воспользуйтесь формой восстановления пароля, чтобы установить его.',
  [GqlError.PostAlreadyPublished]: 'Пост уже опубликован.',
  [GqlError.PostNotFound]: 'Пост не найден.',
  [GqlError.PostNotPublished]: 'Пост не опубликован.',
  [GqlError.PostNotReadyToPublish]: 'Для публикации нужно заполнить обязательные поля.',
  [GqlError.PostPublicationInPast]: 'Пост не может быть опубликован в прошлом.',
  [GqlError.TagNotFound]: 'Тег не найден.',
  [GqlError.TweetNotFound]: 'Твит не найден.',
  [GqlError.Unauthenticated]: 'Ошибка аутентификации.',
  [GqlError.InvalidUserAlias]: 'Неправильный формат ссылки.',
  [GqlError.UserAliasAlreadyExist]: 'Ссылка уже существует.',
  [GqlError.UserAlreadyExist]: 'Такой пользователь уже существует.',
  [GqlError.UserNicknameAlreadyExist]: 'Имя пользователя уже существует.',
  [GqlError.UserNotFound]: 'Пользователь не найден.',
  [GqlError.UseRequestEmailChange]: 'Произошла ошибка при смене почты.',
  [GqlError.WrongStream]: 'Неверный поток.',
  [GqlError.PostCommentsDisabled]: 'Комментарии к посту отключены.',
  [GqlError.UserThirdPartyIdsAlreadyExist]: 'Эта соцсеть уже привязана к другому аккаунту.',
  [GqlError.TagAliasAlreadyExists]: 'Тег с такой ссылкой уже есть.',
  [GqlError.TagWrongMergeTarget]: 'Нельзя сделать редирект на этот тег.',
};

function globalErrorHandler({ context }: GlobalErrorHandlerOptions = {}) {
  return (error: ErrorResponse): void => {
    if (!SSR_MODE) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
          const query = print(error.operation.query);
          const queryHash = await sha256(query);

          withScope((scope) => {
            scope.setExtra('locations', locations);
            scope.setExtra('path', path);
            scope.setExtra('extensions.code', extensions?.code);
            scope.setExtra('extensions.exception', extensions?.exception);
            scope.setExtra('query', query);
            scope.setExtra('variables', error.operation.variables);
            scope.setFingerprint([String(extensions?.code), JSON.stringify(locations), queryHash]);
            captureException(new Error(message), { tags: { kind: 'Apollo:GraphQL' } });
          });
        });
      }

      if (error.networkError) {
        captureException(error.networkError, { tags: { kind: 'Apollo:Network' } });
      }

      const { disableErrorPopup, disableAuthRedirect } = error.operation.getContext();

      if (someGqlErrorIs(GqlError.Unauthenticated)(error) && !disableAuthRedirect) {
        AuthDispatcher.getInstance().dispatch();
      } else {
        const disablePopup = isFunction(disableErrorPopup) ? disableErrorPopup(error) : Boolean(disableErrorPopup);

        if (!disablePopup) {
          getErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage, { toastId: errorMessage }));
        }
      }

      if (PUBLIC_CONFIG.APP_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    if (context) {
      context.statusCode = 500;
    }
  };
}

function getErrorMessages(error: ErrorResponse) {
  const messages = isNetworkError(error)
    ? [NETWORK_ERROR_MESSAGE]
    : getGqlErrors(error).map(mapGraphqlErrorToAppMessage);

  return messages.length > 0 ? messages : [DEFAULT_ERROR_MESSAGE];
}

function mapGraphqlErrorToAppMessage(error: GraphQLError): string {
  return (APP_ERROR_MAP[error.extensions?.code as GqlError] as string | undefined) ?? DEFAULT_ERROR_MESSAGE;
}

function isFunction(value: unknown): value is (error: ErrorResponse) => boolean {
  return typeof value === 'function';
}

export { globalErrorHandler, NETWORK_ERROR_MESSAGE, DEFAULT_ERROR_MESSAGE };
export type { GlobalErrorHandlerOptions };
