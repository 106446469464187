enum SearchSection {
  Articles = 'articles',
  Blogs = 'blogs',
  Comments = 'comments',
}

enum ShowcaseComponent {
  Clickable = 'Clickable',
  Buttons = 'Buttons',
  MediaCard = 'MediaCard',
  Authentication = 'Authentication',
  Settings = 'Settings',
  AccordionMenu = 'AccordionMenu',
  Advertisement = 'Advertisement',
  Flame = 'Flame',
}

enum UserPageContent {
  Posts = 'posts',
  Comments = 'comments',
  Favorites = 'favorites',
  Drafts = 'drafts',
}

export { SearchSection, ShowcaseComponent, UserPageContent };
