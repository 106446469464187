import { createContext, ReactNode, useContext } from 'react';
import seedrandom from 'seedrandom';
import { useServerData } from '../ServerDataProvider';

interface SeededProviderProps {
  children: ReactNode;
}

const SeededRandomProvider = ({ children }: SeededProviderProps): JSX.Element => {
  const { SEED } = useServerData();

  const generator = seedrandom(SEED);

  return <SeedContext.Provider value={generator}>{children}</SeedContext.Provider>;
};

const SeedContext = createContext(seedrandom());

const useRandom = (): Random => useContext(SeedContext);

type Random = () => number;

export { SeededRandomProvider, useRandom };
export type { Random };
