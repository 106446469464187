/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CurrentUserFragmentFragment = { __typename?: 'User', id: string, alias: string, nickname?: string | null, nicknameChangeBlockedUntil?: number | string | null, aliasChangeBlockedUntil?: number | string | null, email: string, description?: string | null, moderationRequired: boolean, permissions: Array<Types.UserPermission>, thirdPartyApps: Array<Types.ThirdPartyApp>, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null, contacts: { __typename?: 'Contacts', vk?: string | null, tg?: string | null, fb?: string | null } };

export const CurrentUserFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CurrentUserFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"alias"}},{"kind":"Field","name":{"kind":"Name","value":"nickname"}},{"kind":"Field","name":{"kind":"Name","value":"nicknameChangeBlockedUntil"}},{"kind":"Field","name":{"kind":"Name","value":"aliasChangeBlockedUntil"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"moderationRequired"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"originalUrl"}},{"kind":"Field","name":{"kind":"Name","value":"cropSettings"}}]}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"thirdPartyApps"}},{"kind":"Field","name":{"kind":"Name","value":"contacts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vk"}},{"kind":"Field","name":{"kind":"Name","value":"tg"}},{"kind":"Field","name":{"kind":"Name","value":"fb"}}]}}]}}]} as unknown as DocumentNode<CurrentUserFragmentFragment, unknown>;