import { UseFormSetError } from 'react-hook-form';
import { Button } from '../../Button';
import { FeedbackFormState } from '../types';
import { FileUploadState } from './FileUploadState';
import { useImageUpload } from './useImageUpload';

interface Props {
  currentFile: string;
  setError: UseFormSetError<FeedbackFormState>;
  onChange: (file: string) => void;
}

// TODO: only image upload is currently supported (!)
const FeedbackImageUpload = ({ currentFile, onChange, setError }: Props): JSX.Element => {
  const { inputRef, fileName, loading, onSelectFile, onUpload, onReset, onCancel } = useImageUpload(onChange, setError);

  return (
    <>
      <label className="my-3 inline-flex min-h-[2.5rem] w-full items-center" htmlFor="upload">
        {loading ? (
          <FileUploadState title={`Загрузка ${fileName}`} onClick={onCancel} buttonText="Отменить" />
        ) : (
          <>
            {currentFile ? (
              <FileUploadState title={fileName} isButtonDisabled={loading} onClick={onReset} buttonText="Удалить" />
            ) : (
              <Button onClick={onUpload} disabled={loading}>
                Приложить файл не больше 10 мб
              </Button>
            )}
            <input
              className="h-0 w-0 opacity-0"
              tabIndex={-1}
              ref={inputRef}
              id="upload"
              type="file"
              onChange={onSelectFile}
              value=""
              accept=".jpg,.jpeg,.png,.gif"
            />
          </>
        )}
      </label>
      {currentFile && <img alt="Upload" className="mx-auto my-14 block max-w-full" src={currentFile} />}
    </>
  );
};

export { FeedbackImageUpload };
