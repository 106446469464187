import { createContext, ReactNode, useContext } from 'react';
import { ImageServiceClient } from './ImageServiceClient';
import { assertIsDefined } from '../isDefined';

interface ImageServiceClientProviderProps {
  client: ImageServiceClient;
  children: ReactNode;
}

const ImageServiceProvider = ({ client, children }: ImageServiceClientProviderProps) => (
  <ImageServiceContext.Provider value={client}>{children}</ImageServiceContext.Provider>
);

const ImageServiceContext = createContext<ImageServiceClient | undefined>(undefined);

function useImageServiceClient(): ImageServiceClient {
  const client = useContext(ImageServiceContext);

  assertIsDefined(client);

  return client;
}

export { ImageServiceProvider, useImageServiceClient };
