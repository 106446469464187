import { useApolloClient, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { useRef } from 'react';
import { Button, ButtonVariant } from '../Button';
import { CreatePostDocument } from './CreatePost.document';
import { ROUTES } from '../../lib/ROUTES';
import PlusIcon from './plus.svg?svgr';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';
import { useOnLoginFormOpen } from '../LoginFormProvider';
import { useOnSettingsFormOpen } from '../SettingsFormProvider';
import { ignoreReject } from '../../lib/ignoreReject';
import { LoginInfoDocument } from '../../react-hooks/useLoginInfo/gql/LoginInfo.document';

interface CreatePostButtonProps {
  variant?: ButtonVariant.Contained | ButtonVariant.Outlined;
  buttonAlwaysVisible?: boolean;
  className?: string;
}

const CreatePostButton = ({
  className,
  variant = ButtonVariant.Contained,
  buttonAlwaysVisible,
}: CreatePostButtonProps) => {
  const apolloClient = useApolloClient();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { data: loginData } = useLoginInfo();
  const [createPost, { loading: creatingPost }] = useMutation(CreatePostDocument);

  const navigate = useNavigate();
  const location = useLocation();
  const onLoginFormOpen = useOnLoginFormOpen();
  const onSettingsFormOpen = useOnSettingsFormOpen();

  const needNickname = !loginData?.loginInfo.user?.nickname;

  const onCreatePost = async () => {
    assertIsDefined(loginData);

    if (loginData.loginInfo.user) {
      if (needNickname) {
        navigate(ROUTES.USER_FILTER.buildPath({ id: loginData.loginInfo.user.alias || loginData.loginInfo.user.id }));
      } else {
        const createPostResult = await ignoreReject(createPost({ variables: { publishAt: Date.now() } }));
        if (createPostResult?.data) {
          navigate(
            ROUTES.BLOG_EDIT.buildPath(
              { id: createPostResult.data.createPost.id },
              { backTo: `${location.pathname}${location.search}` }
            )
          );
        }
      }
    } else {
      onLoginFormOpen({
        onLoginFormClose: () => {
          const freshLoginData = apolloClient.readQuery({ query: LoginInfoDocument });

          if (buttonRef.current && freshLoginData?.loginInfo.user) {
            setTimeout(() => {
              buttonRef.current?.click();
            }, 0);
          }
        },
      });
    }
  };

  return loginData ? (
    !needNickname || buttonAlwaysVisible ? (
      <Button
        clickableRef={buttonRef}
        className={className}
        onClick={onCreatePost}
        disabled={creatingPost}
        variant={variant}
      >
        {creatingPost ? 'Подождите...' : variant === ButtonVariant.Contained ? 'Написать' : 'Написать блог'}
        {variant === ButtonVariant.Contained ? <PlusIcon className="ml-auto" height={24} width={24} /> : null}
      </Button>
    ) : (
      <div className={className}>
        <Button variant={ButtonVariant.InlineText} onClick={() => onSettingsFormOpen('personal')}>
          Задайте имя
        </Button>
        , чтобы написать блог
      </div>
    )
  ) : null;
};

export { CreatePostButton };
