import { tw } from '../../styles/tw';

const CLOSE_ANIMATION_DURATION_MS = 200;

const overlayBase = tw('fixed inset-0 cursor-pointer overflow-hidden opacity-0');

const modalLayer = tw('z-modal');

const subModalLayer = tw('z-sub-modal');

const overlayAfterOpen = tw('animate-reveal-modal');

const overlayBeforeClose = tw('animate-hide-modal');

const contentBase = tw('fixed inset-0 flex cursor-auto flex-col overflow-y-auto md:left-auto');

const contentAfterOpen = tw('animate-slide-in-bottom-modal md:animate-slide-in-right-modal');

const contentBeforeClose = tw('animate-slide-out-bottom-modal md:animate-slide-out-right-modal');

export {
  CLOSE_ANIMATION_DURATION_MS,
  overlayBase,
  modalLayer,
  subModalLayer,
  overlayAfterOpen,
  overlayBeforeClose,
  contentBase,
  contentAfterOpen,
  contentBeforeClose,
};
