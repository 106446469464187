import { createContext, ReactNode } from 'react';

interface StaticRouterContext {
  statusCode?: number;
  url?: string;
}

interface RouterContextProviderProps {
  children: ReactNode;
  context?: StaticRouterContext;
}

const RouterContextProvider = ({ children, context }: RouterContextProviderProps): JSX.Element => (
  <RouterContext.Provider value={context}>{children}</RouterContext.Provider>
);

const RouterContext = createContext<StaticRouterContext | undefined>(undefined);

export { RouterContextProvider, RouterContext };
export type { StaticRouterContext };
