import breakpoints from '@flame-frontend-utils/tiptap/configs/river/breakpoint.json';

/** Sizes are mobile-first. */

/**
 * Pixels, the first interval MUST start at 0.
 *
 * [Xs,S): xs width [S,M): s width [M,L): m width [L,XL): l width [XL,+∞): xl width
 */

enum Width {
  S = breakpoints.s,
  M = breakpoints.m,
  L = breakpoints.l,
  Xl = breakpoints.xl,
}

/** Must be between Xs and S. Minimal supported width. */
const MIN_WIDTH = 375; // Pixels
const MAX_WIDTH = 1600; // Pixels. Sync with Tailwind config!

interface MediaWidth {
  s: string;
  m: string;
  l: string;
  xl: string;
}

const mediaWidth = Object.assign(
  {},
  ...Object.entries(Width).map(([key, value]) => ({ [key.toLowerCase()]: `@media (min-width: ${value}px)` }))
) as MediaWidth;

export { Width, MIN_WIDTH, MAX_WIDTH, mediaWidth };
