import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ButtonVariant, ButtonColor } from '../Button';
import { ROUTES } from '../../lib/ROUTES';
import { tw } from '../../styles/tw';

const tags = [
  {
    alias: 'novosti',
    value: 'Новости',
  },
  {
    alias: 'selebriti',
    value: 'Селебрити',
  },
  {
    alias: 'moda',
    value: 'Мода',
  },
  {
    alias: 'byuti',
    value: 'Бьюти',
  },
  {
    alias: 'hype',
    value: 'Хайп',
  },
];

interface TagMenuProps {
  color?: ButtonColor;
  onClick?: () => void;
}

const TagsMenu = ({ color, onClick }: TagMenuProps): JSX.Element => {
  const location = useLocation();

  const onInnerClick = () => {
    onClick?.();
  };

  return (
    <>
      {tags.map((tag) => {
        const tagPath = ROUTES.TAG_FILTER.buildPath({ id: tag.alias });
        const active = tagPath === location.pathname;

        return (
          <Fragment key={tag.alias}>
            <Button
              color={color}
              className={tw(active && 'font-semibold', 'inline')}
              variant={ButtonVariant.Text}
              to={tagPath}
              onClick={onInnerClick}
            >
              {tag.value}
            </Button>
          </Fragment>
        );
      })}
    </>
  );
};

export { TagsMenu };
