import { Modal, ModalProps } from '@flame-frontend-utils/commons';
import { tw } from '../../styles/tw';

const CLOSE_ANIMATION_DURATION = 200; // ms

type PopupProps = Omit<ModalProps, 'className' | 'overlayClassName'> & {
  className?: string;
  overlayClassName?: string;
};

const Popup = ({ className, overlayClassName, children, ...props }: PopupProps): JSX.Element => (
  <Modal
    {...props}
    overlayClassName={{
      base: tw(defaultOverlay, overlayClassName),
      beforeClose: disappearAnimation,
      afterOpen: appearAnimation,
    }}
    closeTimeoutMS={CLOSE_ANIMATION_DURATION}
    className={tw(defaultContent, className)}
  >
    {children}
  </Modal>
);

const defaultOverlay = tw('fixed inset-0 z-modal flex flex-col items-stretch overflow-auto bg-loader/15 opacity-0');

const appearAnimation = tw('animate-reveal-modal');

const disappearAnimation = tw('animate-hide-modal');

const defaultContent = tw('flex grow flex-col bg-cream p-8 outline-none');

export { Popup };
export type { PopupProps };
