import { layout } from '../../styles/layout';
import { Page } from '../../components/Page';
import { tw } from '../../styles/tw';
import { Button, ButtonVariant } from '../../components/Button';
import { ROUTES } from '../../lib/ROUTES';

const NotFoundPage = (): JSX.Element => (
  <Page
    className={tw(layout, 'mb-5 flex flex-col items-center justify-center')}
    documentTitle="Страница не найдена"
    statusCode={404}
    hideAdvertisement
  >
    <h1 className="mb-4 flex h-[8.75rem] w-[8.75rem] items-center justify-center rounded-full border-2 border-blood text-[2.625rem] text-blood">
      404
    </h1>
    <p className="mb-20">Страница не найдена</p>
    <Button variant={ButtonVariant.Outlined} to={ROUTES.ROOT.buildPath({})}>
      На главную
    </Button>
  </Page>
);

export default NotFoundPage;
export { NotFoundPage };
