import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface OpenGraphProps {
  /** Defaults to APP_NAME */
  title?: string;
  /** Defaults to website */
  type?: string;
  /** Path to image, defaults to favicon */
  image?: string;
  /** Defaults to current url without search (uses CANONICAL_ROBOTS_HOST) */
  url?: string;
  /** Defaults to APP_DESCRIPTION */
  description?: string;
  /** Defaults to ru_RU */
  locale?: string;
  /** Defaults to summary */
  card?: 'summary_large_image' | 'summary';
}

const OpenGraph = ({
  title = PUBLIC_CONFIG.APP_NAME,
  url,
  type = 'website',
  image = `${PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST}/favicon.ico`,
  description = PUBLIC_CONFIG.APP_DESCRIPTION,
  locale = 'ru_RU',
  card = 'summary',
}: OpenGraphProps) => {
  const location = useLocation();

  return (
    <Helmet>
      <meta name="twitter:card" content={card} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta
        property="og:image"
        content={/^http/.test(image) ? image : `${PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST}${image}`}
      />
      <meta
        property="og:url"
        content={new URL(url ?? location.pathname, PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST).toString()}
      />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={locale} />
    </Helmet>
  );
};

export { OpenGraph };
export type { OpenGraphProps };
