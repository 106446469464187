import { configureScope, setUser } from '@sentry/react';
import { useEffect } from 'react';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';

const SentryUserSetter = (): null => {
  const { data } = useLoginInfo();

  useEffect(() => {
    if (data) {
      if (data.loginInfo.user) {
        setUser({ email: data.loginInfo.user.email, id: data.loginInfo.user.id });
      } else {
        configureScope((scope) => scope.setUser(null));
      }
    }
  }, [data]);

  return null;
};

export { SentryUserSetter };
