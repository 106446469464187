export function appendSearchParams(url: string, params: string) {
  const urlObj = new URL(url, 'http://remove-me.com');

  const additionalParams = new URLSearchParams(params);

  additionalParams.forEach((value, key) => {
    urlObj.searchParams.append(key, value);
  });

  return urlObj.toString().replace('http://remove-me.com', '');
}
