/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CurrentUserFragmentFragmentDoc } from '../../../gql/CurrentUserFragment.document';
export type LoginInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoginInfoQuery = { __typename?: 'Query', loginInfo: { __typename?: 'LoginInfo', user?: { __typename?: 'User', id: string, alias: string, nickname?: string | null, nicknameChangeBlockedUntil?: number | string | null, aliasChangeBlockedUntil?: number | string | null, email: string, description?: string | null, moderationRequired: boolean, permissions: Array<Types.UserPermission>, thirdPartyApps: Array<Types.ThirdPartyApp>, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null, contacts: { __typename?: 'Contacts', vk?: string | null, tg?: string | null, fb?: string | null } } | null } };


export const LoginInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LoginInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUserFragment"}}]}}]}}]}},...CurrentUserFragmentFragmentDoc.definitions]} as unknown as DocumentNode<LoginInfoQuery, LoginInfoQueryVariables>;