import { Preview, UploadedImageOptions } from './types';

export class UploadedImage {
  public readonly id: string;

  public readonly previews: Preview[];

  public readonly urlTemplate: string;

  public constructor({ id, urlTemplate, previews }: UploadedImageOptions) {
    this.id = id;
    this.previews = previews;
    this.urlTemplate = urlTemplate;
  }

  public getPreviewUrl(width: number | 'original'): string {
    return this.urlTemplate.replace('{width}', String(width));
  }
}
