/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReactionsFragmentFragment = { __typename?: 'Reactions', likesCount: number, dislikesCount: number, myReaction: Types.Reaction };

export const ReactionsFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ReactionsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Reactions"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"likesCount"}},{"kind":"Field","name":{"kind":"Name","value":"dislikesCount"}},{"kind":"Field","name":{"kind":"Name","value":"myReaction"}}]}}]} as unknown as DocumentNode<ReactionsFragmentFragment, unknown>;