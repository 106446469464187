import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

function useFetchWhenInView(onFetchMore: () => void) {
  const { ref: intersectionObserverRef, inView, entry } = useInView({ delay: 100 });

  useEffect(() => {
    /**
     * Apparently onFetchMore is updated before inView, which leads to additional request even when target element is
     * not actually in view. At the same time, we actually want additional requests as long as target element stays in
     * view.
     */
    if (inView && entry?.target && isInsideViewport(entry.target.getBoundingClientRect())) {
      void onFetchMore();
    }
  }, [entry?.target, inView, onFetchMore]);

  return {
    intersectionObserverRef,
  };
}

function isInsideViewport(rect: DOMRect) {
  return rect.bottom > 0 && rect.top < window.innerHeight;
}

export { useFetchWhenInView };
