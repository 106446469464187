import { ApiError, ImageServiceClient, useImageServiceClient } from '@flame-frontend-utils/commons';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_MESSAGE, NETWORK_ERROR_MESSAGE } from '../lib/apollo';

type UseUploadImageResult = [
  ImageServiceClient['copyOrUpload'],
  {
    loading: boolean;
  }
];

export function useUploadImage(): UseUploadImageResult {
  const client = useImageServiceClient();

  const [loading, setLoading] = useState(false);

  const onLoadImage: ImageServiceClient['copyOrUpload'] = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        return await client.copyOrUpload(...args);
      } catch (error) {
        if (ApiError.is(error)) {
          switch (error.code) {
            case 0:
              toast.error(NETWORK_ERROR_MESSAGE);
              break;
            case 413:
              toast.error('Слишком большой файл');
              break;
            case 400:
            case 422:
              toast.error('Неподдерживаемый формат файла');
              break;
            default:
              toast.error(DEFAULT_ERROR_MESSAGE);
          }
        } else {
          toast.error(DEFAULT_ERROR_MESSAGE);
        }
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [client]
  ) as ImageServiceClient['copyOrUpload'];

  return [onLoadImage, { loading }];
}
