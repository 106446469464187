import { useMutation } from '@apollo/client';
import { ViewAllNotificationsDocument } from './gql/ViewAllNotifications.document';
import { NotificationsDocument } from './gql/Notifications.document';
import { Button, ButtonVariant } from '../../Button';
import { ignoreReject } from '../../../lib/ignoreReject';

const MarkAsViewedButton = () => {
  const [mutate, { loading }] = useMutation(ViewAllNotificationsDocument, { refetchQueries: [NotificationsDocument] });

  return (
    <Button
      className="mb-9 mt-5 w-full justify-start"
      variant={ButtonVariant.Outlined}
      onClick={() => ignoreReject(mutate())}
    >
      {loading ? 'Отмечаем... ' : 'Отметить как прочитанные'}
    </Button>
  );
};

export { MarkAsViewedButton };
