import { ForwardedRef, forwardRef, InputHTMLAttributes, useImperativeHandle, useRef, useEffect } from 'react';
import { useNavigationType, NavigationType } from 'react-router-dom';
import { tw } from '../../styles/tw';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & { autoFocusOnMount?: boolean };

const TextInput = forwardRef(
  ({ autoFocusOnMount, className, ...restProps }: TextInputProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);

    const navAction = useNavigationType();

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    useEffect(() => {
      if (autoFocusOnMount && navAction !== NavigationType.Pop) {
        inputRef.current?.focus();
      }
    }, [autoFocusOnMount, navAction]);

    return (
      <input
        className={tw(
          'w-full border-y border-blood bg-transparent px-0 py-3 placeholder:text-half-blood focus:outline-none disabled:opacity-50',
          className
        )}
        {...restProps}
        ref={inputRef}
      />
    );
  }
);

export { TextInput };
export type { TextInputProps };
