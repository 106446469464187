import { Page } from '../../components/Page';
import { layout } from '../../styles/layout';
import { PrivacyContent } from './PrivacyContent';
import { AdvertisingContent } from './AdvertisingContent';
import { AgreementContent } from './AgreementContent';
import { ModerationRulesContent } from './ModerationRulesContent';
import { AboutContent } from './AboutContent';
import { JobOpeningsContent } from './JobOpeningsContent';
import { tw } from '../../styles/tw';
import { ROUTES } from '../../lib/ROUTES';

interface InfoPageProps {
  type: InfoType;
}

enum InfoType {
  About = 'ABOUT',
  Privacy = 'PRIVACY',
  Advertising = 'ADVERTISING',
  Agreement = 'AGREEMENT',
  ModerationRules = 'MODERATION_RULES',
  JobOpenings = 'JOB_OPENINGS',
}

const InfoPage = ({ type }: InfoPageProps) => (
  <Page
    className={tw(layout, 'relative grid items-start gap-12 pt-6 lg:grid-cols-[1fr_2fr] xl:grid-cols-[1fr_1fr]')}
    documentTitle={getTitle(type)}
    canonicalUrl={getCanonicalUrl(type)}
  >
    <div className="self-stretch">
      <h1 className="sticky top-[calc(var(--header-height)_+_1.5rem)] text-xl">{getTitle(type)}</h1>
    </div>
    <div className="[&>*]:mb-5">{getContent(type)}</div>
  </Page>
);

function getTitle(type: InfoType): string {
  switch (type) {
    case InfoType.About:
      return 'О нас';
    case InfoType.Privacy:
      return 'Политика по защите персональных данных';
    case InfoType.Advertising:
      return 'Реклама';
    case InfoType.Agreement:
      return 'Пользовательское соглашение сайта spletnik.ru';
    case InfoType.ModerationRules:
      return 'Правила модерации пользовательского контента на Spletnik';
    case InfoType.JobOpenings:
      return 'Вакансии';
    default:
      throw new Error('Unknown info type');
  }
}

function getCanonicalUrl(type: InfoType): string {
  switch (type) {
    case InfoType.About:
      return ROUTES.ABOUT.buildPath({});
    case InfoType.Privacy:
      return ROUTES.PRIVACY.buildPath({});
    case InfoType.Advertising:
      return ROUTES.ADVERTISING.buildPath({});
    case InfoType.Agreement:
      return ROUTES.AGREEMENT.buildPath({});
    case InfoType.ModerationRules:
      return ROUTES.MODERATION_RULES.buildPath({});
    case InfoType.JobOpenings:
      return ROUTES.JOB_OPENINGS.buildPath({});
    default:
      throw new Error('Unknown info type');
  }
}

function getContent(type: InfoType) {
  switch (type) {
    case InfoType.About:
      return <AboutContent />;
    case InfoType.Privacy:
      return <PrivacyContent />;
    case InfoType.Advertising:
      return <AdvertisingContent />;
    case InfoType.Agreement:
      return <AgreementContent />;
    case InfoType.ModerationRules:
      return <ModerationRulesContent />;
    case InfoType.JobOpenings:
      return <JobOpeningsContent />;
    default:
      throw new Error('Unknown info type');
  }
}

export { InfoPage, InfoType };
