import { ComponentType, SVGAttributes } from 'react';

interface StatsIconProps {
  Icon: ComponentType<SVGAttributes<SVGElement>>;
  counter: number;
  hideZero?: boolean;
}

const StatsIcon = ({ Icon, counter, hideZero }: StatsIconProps): JSX.Element | null =>
  !counter && hideZero ? null : (
    <div className="flex items-center">
      <Icon width={12} height={12} />
      <div className="ml-1">{counter}</div>
    </div>
  );

export { StatsIcon };
