import { tw } from '../../styles/tw';
import { Button } from '../Button';

interface SidebarHeaderProps {
  title: string;
  onClose: () => void;
  className?: string;
}

const SidebarHeader = ({ title, className, onClose }: SidebarHeaderProps): JSX.Element => (
  <div className={tw('mb-20 flex items-center justify-between', className)}>
    <h3 className="text-[1.5625rem] leading-[1.625rem] tracking-[-0.015em] [font-feature-settings:'pnum'_on,_'onum'_on,_'salt'_on] md:text-[1.875rem]">
      {title}
    </h3>
    <Button onClick={onClose}>Закрыть</Button>
  </div>
);

export { SidebarHeader };
