import { ApolloLink } from '@apollo/client';

/**
 * Problem: Sometimes Apollo Client includes the one fragment multiple times. Workaround: Remove duplicate fragments
 * from query definitions
 *
 * We can't use dedupeFragments: true, because it simply removes nested fragments, making fragments with nested
 * fragments broken and unusable on their own.
 *
 * @see https://github.com/dotansimha/graphql-code-generator/issues/3063#issuecomment-842536997
 */
export function dedupeFragmentsLink() {
  return new ApolloLink((operation, forward) => {
    const previousDefinitions = new Set<string>();
    const definitions = operation.query.definitions.filter((def) => {
      if (def.kind !== 'FragmentDefinition') return true;
      const name = `${def.name.value}-${def.typeCondition.name.value}`;
      if (previousDefinitions.has(name)) {
        return false;
      }
      previousDefinitions.add(name);
      return true;
    });
    // eslint-disable-next-line no-param-reassign
    operation.query = {
      ...operation.query,
      definitions,
    };
    return forward(operation);
  });
}
